import { gql, useQuery } from "@apollo/client";

const TRANSACTIONS_QUERY = gql`
  query GetBuyData($portfolioId: Long, $orderStatus: String) {
    portfolio(id: $portfolioId) {
      id
      currency {
        securityCode
      }
      portfolioReport(
        adjustPositionsBasedOnOpenTradeOrders: false
        calculateExpectedAmountBasedOpenTradeOrders: false
      ) {
        portfolioId
        accountBalanceAdjustedWithOpenTradeOrders: accountBalance
      }
      tradeOrders(typeCodes: ["WD"], orderStatus: $orderStatus) {
        id
        tradeAmount
      }
    }
  }
`;

interface PortfolioTransactionsQuery {
  portfolio: {
    currency: {
      securityCode: string;
    };
    portfolioReport: {
      accountBalanceAdjustedWithOpenTradeOrders: number;
    };
    tradeOrders: Array<{
      id: string;
      tradeAmount: number;
    }>;
  };
}

export const useGetBuyData = (portfolioId: string | undefined) => {
  const { loading, error, data, refetch } =
    useQuery<PortfolioTransactionsQuery>(TRANSACTIONS_QUERY, {
      variables: { portfolioId, orderStatus: "5" },
      fetchPolicy: "network-only",
    });

  const tradeOrdersInExectuion = useQuery<PortfolioTransactionsQuery>(
    TRANSACTIONS_QUERY,
    {
      variables: { portfolioId, orderStatus: "6" },
      fetchPolicy: "network-only",
    }
  );

  // Initialize totalWithdrawals to zero before processing the data
  let totalWithdrawals = 0;
  let totalWithdrawalsInExecutionState = 0;

  // Calculate the total withdrawal amount if data is available
  if (data) {
    totalWithdrawals = data.portfolio.tradeOrders.reduce(
      (acc, order) => acc + order.tradeAmount,
      0
    );
  }

  // Calculate the total withdrawal amount in execution state if data is available
  if (tradeOrdersInExectuion.data) {
    totalWithdrawals +=
      tradeOrdersInExectuion.data.portfolio.tradeOrders.reduce(
        (acc, order) => acc + order.tradeAmount,
        0
      );
  }

  return {
    loading,
    error,
    data: data && {
      availableCash:
        data.portfolio.portfolioReport
          .accountBalanceAdjustedWithOpenTradeOrders -
        totalWithdrawals -
        totalWithdrawalsInExecutionState,
      currency: data.portfolio.currency.securityCode,
    },
    refetch,
  };
};
