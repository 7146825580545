import { CapacitorHttp, HttpOptions } from "@capacitor/core";
import { isMobileApp } from "config";
import HttpStatus from "http-status-codes";

/**
 * This wrapper provider a fetch-like interface but uses
 * the Capacitor http plugin as the network layer.
 * @param url
 * @param options
 * @returns
 */
export const capacitorHttpAsFetch = async (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
): Promise<Response> => {
  // if cordova is not available use web fetch
  if (!isMobileApp) {
    console.error("Didn't recofnize mobile app. Usin fetch!");

    return fetch(url, options);
  }

  // fetch signature uses name body so we map it to data for Capacitor
  const { body, ...rest } = options;
  const request: HttpOptions = {
    url,
    data: body,
    ...rest,
  };

  const response = await CapacitorHttp.request(request);

  const responseBody =
    typeof response.data === `object`
      ? JSON.stringify(response.data)
      : response.data;

  return new Response(responseBody, {
    status: response.status,
    statusText: HttpStatus.getStatusText(response.status),
    headers: response.headers,
  });
};
