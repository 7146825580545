import { Portfolio, useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { useParams } from "react-router-dom";

export enum canTradeMode {
  ANY,
  SELECTED,
  SELECTED_ANY,
}

export const tradableTag = "Tradeable";
export const TradePermissionGroup = "CP_TRADING" as const;

export const isPortfolioTradable = (portfolio: Portfolio) => {
  return portfolio.portfolioGroups.some(
    (group) => group.code === TradePermissionGroup
  );
};

/*
 * Checks if user or portfolio is eligible to trade
 * @param mode: mode to apply when checking if eligible to trade
 * SELECTED - check only if the currently selected portfolio can trade
 * ANY - check if any of the user's portfolios can trade
 * SELECTED_ANY - use SELECTED_ONLY if there is a selected portfolio, else do ANY
 * @return boolean - whether trading is allowed
 */
export const useCanTrade = (mode = canTradeMode.SELECTED) => {
  const { portfolioId } = useParams();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  const isAnyPortfolioTradable = portfolios.some(isPortfolioTradable);
  const selectedPortfolio = portfolios.filter(
    (portfolio) =>
      portfolioId !== undefined && portfolio.id === parseInt(portfolioId, 10)
  );
  const isSelectedPortfolioTradable =
    selectedPortfolio.some(isPortfolioTradable);

  switch (mode) {
    case canTradeMode.ANY:
      return isAnyPortfolioTradable;
    case canTradeMode.SELECTED:
      return isSelectedPortfolioTradable;
    case canTradeMode.SELECTED_ANY:
      if (portfolioId !== undefined) return isSelectedPortfolioTradable;
      return isAnyPortfolioTradable;
    default:
      return false;
  }
};
