import { useEffect } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import { OnboardingFooter } from "./OnboardingFooter";
import iphoneImage from "../../../../src/assets/images/kvarnx-phone.png";
import logo from "../../../../src/assets/images/logo_onboarding.png";

export const ThankYouKycUpdateInfo = () => {
  const { t, i18n } = useModifiedTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get("lang");
    if (lang && i18n) {
      i18n.changeLanguage(lang);
    }
  }, [location, i18n]);

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container py-6 px-4 mx-auto max-w-6xl">
        <img src={logo} alt="Kvarn X Logo" className="w-44 h-auto" />
      </div>
      <div className="flex flex-grow items-center">
        <div className="container flex flex-col md:flex-row items-center px-4 mx-auto max-w-6xl">
          <div className="mb-8 md:mb-0 md:w-1/2">
            <h1 className="mb-8 text-4xl font-bold text-kvarn-dark-green">
              {t("kycUpdateThankYou.title")}
            </h1>
            <p className="mb-6 text-lg">{t("kycUpdateThankYou.text")}</p>
            <button
              onClick={handleBackToHome}
              className="py-3 px-6 w-full md:w-auto text-lg font-semibold text-kvarn-dark-green hover:text-kvarn-light-green bg-kvarn-light-green hover:bg-kvarn-dark-green rounded-full border border-gray-400 transition-colors"
            >
              {t("kycUpdateThankYou.backToHome")}
            </button>
          </div>
          <div className="md:w-1/2">
            <img
              src={iphoneImage}
              alt="Kvarn X App Interface"
              className="mx-auto w-full max-w-md"
            />
          </div>
        </div>
      </div>
      <OnboardingFooter />
    </div>
  );
};
