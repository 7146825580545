import { ReactNode } from "react";
import classNames from "classnames";

interface GainLoseColoringProps {
  children: ReactNode;
  value: number;
  darkBackground?: boolean;
}

export const GainLoseColoring = ({
  children,
  value,
  darkBackground = false,
}: GainLoseColoringProps) => (
  <div
    className={classNames({
      "text-[#FB7691]": value < 0 && !darkBackground,
      "text-[#108570]": value > 0 && !darkBackground,
      "text-red-300": value < 0 && darkBackground,
      "text-green-300": value > 0 && darkBackground,
    })}
  >
    {children}
  </div>
);
