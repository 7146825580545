import { useEffect, useRef, useCallback } from "react";
import throttle from "lodash/throttle";

const STORAGE_FREQUENCY_MS = 1000;
const FORM_STORAGE_KEY = "formStorage";

const useFormStorage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storedItem = window.localStorage.getItem(FORM_STORAGE_KEY);
  const storedValue = storedItem ? JSON.parse(storedItem) : null;

  const pendingChanges = useRef<Record<string, string>>({});

  const saveToLocalStorage = () => {
    try {
      const storedData = JSON.parse(
        window.localStorage.getItem(FORM_STORAGE_KEY) || "{}"
      );
      Object.assign(storedData, pendingChanges.current);
      window.localStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(storedData));
      pendingChanges.current = {};
    } catch (error) {
      console.warn(
        `Error writing to localStorage key “${FORM_STORAGE_KEY}”:`,
        error
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSave = useCallback(
    throttle(saveToLocalStorage, STORAGE_FREQUENCY_MS, { trailing: true }),
    []
  );

  const save = (key: string, value: string) => {
    pendingChanges.current[key] = value;
    throttledSave();
  };

  useEffect(() => {
    return () => throttledSave.cancel();
  }, [throttledSave]);

  return [storedValue, save];
};

export default useFormStorage;
