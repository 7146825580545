import { useModifiedTranslation } from "hooks/useModifiedTranslation";

const WelcomeHeader = ({
  titleTranslationKey,
  subtitleTranslationKeys,
}: {
  titleTranslationKey: string;
  subtitleTranslationKeys: string[];
}) => {
  const { t } = useModifiedTranslation();
  return (
    <>
      <h1 className="p-2 text-2xl font-normal leading-normal text-kvarn-light-green">
        {t(titleTranslationKey)}
      </h1>
      {subtitleTranslationKeys.map((key) => (
        <h2
          key={key}
          className="p-2 text-base font-light leading-normal text-white"
        >
          {t(key)}
        </h2>
      ))}
    </>
  );
};

const WelcomeInfoPrivate = () => {
  return (
    <div className="p-8 bg-kvarn-dark-green">
      <WelcomeHeader
        titleTranslationKey="onboardingPage.welcomeInfo.title"
        subtitleTranslationKeys={[
          "onboardingPage.welcomeInfo.subtitle1",
          "onboardingPage.welcomeInfo.subtitle2",
          "onboardingPage.welcomeInfo.subtitle3",
          "onboardingPage.welcomeInfo.subtitle4",
          "onboardingPage.welcomeInfo.subtitle5",
        ]}
      />
    </div>
  );
};

const WelcomeInfoBusiness = () => {
  return (
    <div className="p-8 bg-kvarn-dark-green">
      <WelcomeHeader
        titleTranslationKey="onboardingPage.welcomeInfoBusiness.title"
        subtitleTranslationKeys={[
          "onboardingPage.welcomeInfoBusiness.subtitle1",
          "onboardingPage.welcomeInfoBusiness.subtitle2",
          "onboardingPage.welcomeInfoBusiness.subtitle3",
          "onboardingPage.welcomeInfoBusiness.subtitle4",
          "onboardingPage.welcomeInfoBusiness.subtitle5",
          "onboardingPage.welcomeInfoBusiness.subtitle6",
          "onboardingPage.welcomeInfoBusiness.subtitle7",
        ]}
      />
    </div>
  );
};

const WelcomeInfoKycUpdate = () => {
  return (
    <div className="p-8 bg-kvarn-dark-green">
      <WelcomeHeader
        titleTranslationKey="onboardingPage.welcomeInfoKyc.title"
        subtitleTranslationKeys={[
          "onboardingPage.welcomeInfoKyc.subtitle1",
          "onboardingPage.welcomeInfoKyc.subtitle2",
          "onboardingPage.welcomeInfoKyc.subtitle3",
          "onboardingPage.welcomeInfoKyc.subtitle4",
          "onboardingPage.welcomeInfoKyc.subtitle5",
        ]}
      />
    </div>
  );
};

const WelcomeInfoPublic = () => {
  return (
    <div className="p-8 bg-kvarn-dark-green">
      <WelcomeHeader
        titleTranslationKey="onboardingPage.welcomeInfoPublic.title"
        subtitleTranslationKeys={[
          "onboardingPage.welcomeInfoPublic.subtitle1",
          "onboardingPage.welcomeInfoPublic.subtitle2",
          "onboardingPage.welcomeInfoPublic.subtitle3",
          "onboardingPage.welcomeInfoPublic.subtitle4",
          "onboardingPage.welcomeInfoPublic.subtitle5",
        ]}
      />
    </div>
  );
};

export const WelcomeInfo = () => {
  const pathname = window.location.pathname;

  if (pathname === "/onboarding/yritysOnboarding") {
    return <WelcomeInfoBusiness />;
  } else if (pathname === "/update-info") {
    return <WelcomeInfoKycUpdate />;
  } else if (pathname === "/onboarding/publicOnboarding") {
    return <WelcomeInfoPublic />;
  } else {
    return <WelcomeInfoPrivate />;
  }
};
