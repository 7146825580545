import { lazy } from "react";
import { MainLayout } from "layouts/MainLayout/MainLayout";
import { Navigate, useRoutes } from "react-router-dom";
import { ThankYouKycUpdateInfo } from "views/onboarding/components/ThankYouKycUpdateInfo";
import { WaitPortfolioActivationInfo } from "views/onboarding/components/WaitPortfolioActivationInfo";
import { KycUpdateFormView } from "views/onboarding/kycUpdate";
import { OnboardingNeededWrapper } from "views/onboardingNeeded/OnboardingNeeded";

const Onboarding = lazy(() =>
  import("./onboarding").then((module) => ({ default: module.FormPage }))
);

export const authUserMainRoutes = [
  {
    path: "",
    element: <OnboardingNeededWrapper />,
  },
  {
    path: "onboarding/:formKey",
    element: <Onboarding />,
  },
  {
    path: "onboarding",
    element: <OnboardingNeededWrapper />,
  },
  {
    path: "wait-portfolion-activation",
    element: <WaitPortfolioActivationInfo />,
  },
  {
    path: "update-info",
    element: <KycUpdateFormView />,
  },
  {
    path: "thank-you-kyc-update",
    element: <ThankYouKycUpdateInfo />,
  },
];

export const AuthUserRoutes = () =>
  useRoutes([
    {
      path: "",
      element: <MainLayout />,
      children: [
        ...authUserMainRoutes,
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
      ],
    },
  ]);
