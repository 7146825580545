import React, { useEffect, useState } from "react";

interface CryptoLogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string | undefined;
}

export const CryptoLogo = ({ name, ...props }: CryptoLogoProps) => {
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    let unmounted = false;
    if (name) {
      const importLogo = async (): Promise<void> => {
        try {
          const image = (
            await import(`assets/crypto/${name.toLowerCase()}.png`)
          ).default;
          if (!unmounted) {
            setImageSrc(image);
          }
        } catch (error) {
          if (!unmounted) {
            setImageSrc("");
          }
        }
      };
      importLogo();
    }
    return () => {
      unmounted = true;
    };
  }, [name]);

  return imageSrc ? <img src={imageSrc} alt={name} {...props} /> : null;
};
