export const getLastUsedLinkedContact = () => {
  return localStorage.getItem("linkedContact");
};

export const setLastUsedLinkedContact = (linkedContact: string | undefined) => {
  if (!linkedContact) {
    localStorage.removeItem("linkedContact");
    return;
  }
  localStorage.setItem("linkedContact", linkedContact);
};
