import { ReactComponent as CheckCircle } from "assets/CheckCircle.svg";
import { Center } from "../Center/Center";

interface CheckMarkProps {
  center?: boolean;
}

export const CheckMark = ({ center = false }: CheckMarkProps) => {
  const CheckNode = <CheckCircle className="w-16 h-16" />;
  return center ? <Center>{CheckNode}</Center> : CheckNode;
};
