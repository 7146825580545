export const CONTAINERS = {
  HOLDINGS_LIST: "holdings-list-container",
  TRADING_LIST: "trading-list-container",
  BUY_MODAL: "buy-modal-container",
  SELL_MODAL: "sell-modal-container",
  SECURITIES_LIST_ROW: "securities-list-row",
  TRANSACTION_DETAILS: "transaction-details-container",
};

export const LABELS = {
  ASSET_NAME: "asset-name-label",
  MIN_TRADE_AMOUNT: "min-trade-amount-label",
  TRANSACTIONS: {
    ASSET_NAME: "transaction-asset-name-label",
    PORTFOLIO_NAME: "transaction-portfolio-name-label",
    DATE: "transaction-date-label",
    AMOUNT: "transaction-amount-label",
    TYPE: "transaction-type-label",
    SUM: "transaction-sum-label",
  },
};

export const INPUTS = {
  TRADE_AMOUNT: "trade-amount-input",
};

export const BUTTONS = {
  CLOSE_MODAL: "close-modal-button",
  MAIN_MENU: "main-menu-button",
};
