/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { LoadingIndicator } from "components";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { ApiError } from "./components/ApiError";
import { KycForm } from "./components/KycForm";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingHeader } from "./components/OnboardingHeader";
import { WelcomeInfo } from "./components/WelcomeInfo";
import { ProcessStatus } from "./types";
import { useGetUserInfo } from "../../api/user/useGetUserInfo";
import { Contact, useUpdateUserInfo } from "../../api/user/useUpdateUserInfo";

export const KycUpdateFormView = () => {
  const navigate = useNavigate();
  const [processState, setProcessState] = useState<any>(null);

  const {
    loading: userInfoLoading,
    error: userInfoError,
    data: userInfo,
  } = useGetUserInfo();

  const {
    updateUserInfo,
    isLoading: updateIsLoading,
    error: updateError,
  } = useUpdateUserInfo();

  useEffect(() => {
    fetch("/processStateKyc.json")
      .then((response) => response.json())
      .then((data) => setProcessState(data))
      .catch((error) =>
        console.error("Error loading processState.json:", error)
      );
  }, []);

  if (!processState || userInfoLoading) {
    return <LoadingIndicator center />;
  }

  if (userInfoError) {
    console.error("Error fetching user info:", userInfoError);
    return <ApiError resetApiError={() => window.location.reload()} />;
  }

  const updatedFormOptions = processState
    ? {
        ...processState.formOptions,
        language: "FI",
      }
    : null;

  const { status, formDefinition, formOptions } = processState;

  const ready = status === ProcessStatus.READY && formDefinition && formOptions;

  const showLoading =
    status === ProcessStatus.LOADING || status === ProcessStatus.SUBMITTING;

  // Parse profileDataString
  const profileData = userInfo?.profileDataString
    ? Object.fromEntries(
        userInfo.profileDataString.split(",\n").map((item) => {
          const [key, value] = item.split("=");
          return [key.trim(), value.trim()];
        })
      )
    : {};

  // Use the userInfo data to populate the updatedInitialFormData
  const updatedInitialFormData = {
    userProfile: {
      email: userInfo?.addresses?.[0]?.email || "",
      contactId: userInfo?.contactId || "",
      email_verified: true,
      family_name: userInfo?.name?.split(" ").slice(-1)[0] || "",
      given_name: userInfo?.name?.split(" ").slice(0, -1).join(" ") || "",
      linked_contact: userInfo?.externalId || "",
      language: userInfo?.language?.name || "",
      name: userInfo?.name || "",
      sub: userInfo?.profile?.id?.toString() || "",
      phone_number: userInfo?.addresses?.[0]?.phone1 || "",
      address: {
        street_address: userInfo?.addresses?.[0]?.address1 || "",
        locality: userInfo?.addresses?.[0]?.city || "",
        postal_code: userInfo?.addresses?.[0]?.zipCode || "",
        country: userInfo?.addresses?.[0]?.country || "",
      },
      birthdate: profileData["contact.trapets.dateofbirth"] || "",
      nationality: userInfo?.nationality?.code || "",
      tax_country: userInfo?.taxCountry?.code || "",
    },

    userGroups: userInfo?.tags
      ? (Array.isArray(userInfo.tags)
          ? userInfo.tags.join(", ")
          : userInfo.tags
        ).replace(/,\s*$/, "")
      : "",
    classification: userInfo?.classification?.code || "",
    juridical: userInfo?.juridical?.code || "",
    createdDate: userInfo?.createdDate || "",
    status: userInfo?.status || "",
    profileData: {
      ...profileData,
      fatcaStatus: profileData["contact.muuttiedot.fatcayksityishenkilo"] || "",
      riskProfile: profileData["contact.profiling.risk"] || "",
      investmentHorizon: profileData["contact.profiling.horizon"] || "",
      investmentFrequency: profileData["contact.profiling.frequency"] || "",
      averageInvestment:
        profileData["contact.profiling.averageInvestment"] || "",
      totalWealth: profileData["contact.profiling.kokonaisvarallisuus"] || "",
      experience: profileData["contact.profiling.years"] || "",
      cryptoexperience: profileData["contact.profiling.kokemuskryptot"] || "",
      cryptoexperienceinyears:
        profileData["contact.profiling.kokemuskryptotvuosia"] || "",
      originOfAssets: profileData["contact.companyDetails.origin"] || "",
      palkkatulo: profileData["contact.profiling.palkkatulo"] !== "false",
      yritystulo: profileData["contact.profiling.yritystulo"] !== "false",
      saastot: profileData["contact.profiling.saastot"] !== "false",
      perintolahja: profileData["contact.profiling.perintolahja"] !== "false",
      sijoitustuotot:
        profileData["contact.profiling.sijoitustuotot"] !== "false",
      asuntokiinteisto:
        profileData["contact.profiling.asuntokiinteisto"] !== "false",
      yritysmyynti: profileData["contact.profiling.yritysmyynti"] !== "false",
      muumyyntitulo: profileData["contact.profiling.muumyyntitulo"] !== "false",
      vakuutusmaksu: profileData["contact.profiling.vakuutusmaksu"] !== "false",
      avustukset: profileData["contact.profiling.avustukset"] !== "false",
      stocks: profileData["contact.profiling.stocks"] !== "false",
      bonds: profileData["contact.profiling.bonds"] !== "false",
      funds: profileData["contact.profiling.funds"] !== "false",
      etf: profileData["contact.profiling.etf"] !== "false",
      derivatives: profileData["contact.profiling.derivatives"] !== "false",
      structured: profileData["contact.profiling.structured"] !== "false",
      cryptocurrencies:
        profileData["contact.profiling.cryptocurrencies"] !== "false",
      other: profileData["contact.profiling.other"] !== "false",
    },
  };

  const handleSubmit = async (formData: any) => {
    try {
      const importData = formData.import || {};

      const profileAttributes = Object.entries(importData)
        .filter(([key]) => key.startsWith("c_profileAttribute_"))
        .map(([key, value]) => {
          // Replace c_profileAttribute_ and convert remaining underscores to dots
          const attributeKey = key
            .replace("c_profileAttribute_", "")
            .replace(/_/g, ".");

          // Define type based on the attribute
          let typeAnnotation = ":string";

          // Boolean fields
          if (
            attributeKey.match(
              /^(contact\.profiling\.(palkkatulo|yritystulo|saastot|perintolahja|sijoitustuotot|asuntokiinteisto|yritysmyynti|muumyyntitulo|vakuutusmaksu|avustukset|stocks|bonds|funds|etf|derivatives|structured|cryptocurrencies|other)|contact\.muuttiedot\.(tilaan|kayttoehdot))$/
            )
          ) {
            typeAnnotation = ":boolean";
          }
          // Date fields
          else if (
            attributeKey.match(
              /^(contact\.trapets\.dateofbirth|contact\.profiling\.completeDate)$/
            )
          ) {
            typeAnnotation = ":string";
          }

          return `${attributeKey}=${value}${typeAnnotation}`;
        })
        .join("#");

      const contact: Contact = {
        contactId: importData.c_contactId || userInfo?.contactId || "",
        name: importData.c_name || "",
        type: importData.c_type || "1",
        taxCountry: importData.c_taxCountry || "",
        juridical: importData.c_juridical || "",
        status: importData.c_status || "",
        classification: importData.c_classification || "PROF",
        identity: importData.c_identity || "ID",
        profileAttributes: profileAttributes,
        address1: importData.c_address1 || "",
        city: importData.c_city || "",
        country: importData.c_country || "FI", // Adjust if dynamic
        zipCode: importData.c_zipCode || "",
        email: importData.c_email || "",
        electronicCommunication: importData.c_electronicCommunication || false, // Update if dynamic
        marketingCommunication: importData.c_marketingCommunication || false, // Update if dynamic
        nationality: importData.c_nationality || "",
        phone1: importData.c_phone1 || "",
        memo: `Updated at ${new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ")}`,
        // Add any additional fields as necessary
      };

      // If you need to handle 'p_' prefixed fields, map them here
      // Example:
      // const pData = formData.p || {};
      // contact.p_field = pData.p_field || "";

      const response = await updateUserInfo([contact]);
      console.log("User info updated successfully:", response.message);
      navigate("/thank-you-kyc-update");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <>
      {ready && (
        <div className="bg-white">
          <OnboardingHeader />
          <div className="flex justify-center w-full">
            <div className="flex flex-wrap justify-center pt-3 lg:pt-10 w-full lg:max-w-[1400px]">
              <div className="lg:sticky lg:top-2 px-4 pt-2 mb-8 w-full lg:w-2/5 h-fit">
                <WelcomeInfo />
              </div>
              <div className="w-full lg:w-3/5">
                <KycForm
                  initialFormData={updatedInitialFormData}
                  formOptions={updatedFormOptions}
                  formDefinition={formDefinition}
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
          <OnboardingFooter />
        </div>
      )}
      {(showLoading || updateIsLoading) && (
        <div className="h-screen">
          <LoadingIndicator center />
        </div>
      )}
      {(status === ProcessStatus.ERROR || userInfoError || updateError) && (
        <ApiError resetApiError={() => window.location.reload()} />
      )}
    </>
  );
};
