import { KeycloakUserInfo } from "services/keycloakService";
export type InitialFormData = {
  campaignCode?: string;
  userProfile: KeycloakUserInfo;
};

// Only typing the stuff we use
export interface FormChangeEvent {
  changed: {
    value?: string;
    instance?: {
      checkValidity?: () => boolean;
    };
    component?: {
      type?: string;
      id: string;
      key?: string;
    };
  };
}
// Only typing the stuff we use
export interface FormError {
  component: { id: string };
  message: string;
}

export enum ProcessStatus {
  LOADING = "LOADING",
  READY = "READY",
  SUBMITTING = "SUBMITTING",
  ERROR = "ERROR",
}

export type FormDefinition = Record<string, unknown>;

type NullState = {
  formDefinition: null;
  formOptions: null;
  initialFormData: null;
  taskId: null;
  processInstanceId: null;
};

type ProcessLoadingState = NullState & {
  status: ProcessStatus.LOADING;
};

type ProcessErrorState = NullState & {
  status: ProcessStatus.ERROR;
};

type ValidState = {
  formDefinition: FormDefinition;
  formOptions: Object;
  initialFormData: InitialFormData;
  taskId: string;
  processInstanceId: string;
};

type ProcessReadyState = ValidState & {
  status: ProcessStatus.READY;
};

type ProcessSubmittingState = ValidState & {
  status: ProcessStatus.SUBMITTING;
};

export type ProcessState =
  | ProcessLoadingState
  | ProcessReadyState
  | ProcessSubmittingState
  | ProcessErrorState;
