import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { ReactComponent as CopyIcon } from "assets/copy-document.svg";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { useDepositablePortfolioSelect } from "./useDepositablePortfolioSelect";
import { CashAccountSelect } from "../components/CashAccountSelect";
import { usePortfoliosAccountsState } from "../usePortfoliosAccountsState";

interface XS2AOptions {
  onFinished: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void;
  onLoad: () => void;
  onClose: () => void;
  autoClose: boolean;
}

declare global {
  interface Window {
    XS2A: {
      startFlow: (clientToken: string, options: XS2AOptions) => void;
    };
    onXS2AReady: (response: ImportDepositQueryResponse) => void;
  }
}

interface ImportDepositQueryResponse {
  clientToken: string;
  extId: string;
  sessionId: string;
}

export const BankDepositModalContent = () => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  const portfolioSelectProps = useDepositablePortfolioSelect();
  const { portfolioId } = portfolioSelectProps;

  const { accountsLoading, ...cashAccountSelectProps } =
    usePortfoliosAccountsState(portfolioId);

  const foundPortfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId
  );
  const foundPortfolioShortName =
    (foundPortfolio ? foundPortfolio.shortName : portfolios[0].shortName) || "";
  const portfolioShortName = foundPortfolioShortName.toString() || "";

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      // Show a success message or toast, if desired
    } catch (err) {
      console.error("Failed to copy text: ", err);
      // Show an error message or toast, if desired
    }
  };

  const currency = cashAccountSelectProps.currentCashAccount?.currency;

  // get the available cash adjusted for open orders
  const { data: { availableCash = 0 } = {} } = useGetBuyData(
    portfolioId.toString()
  );

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <CashAccountSelect
        {...cashAccountSelectProps}
        {...portfolioSelectProps}
        accountSelectLabel={t("moneyModal.toBankAccount")}
        availableCash={availableCash}
      />
      <hr className="mb-3" />
      {currency === "EUR" ? (
        <div className="gap-1">
          <div className="text-sm text-gray-600">
            {t("moneyModal.accountNumber")}:
          </div>
          <p className="inline-flex mb-3">FI79 7960 0139 8908 22</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("FI79 7960 0139 8908 22")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.bicNumber")}:
          </div>
          <p className="inline-flex mb-3">NARYFIH2</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("NARYFIH2")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.message")}:
          </div>
          <p className="inline-flex mb-3">{portfolioShortName}</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard(String(portfolioShortName))}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.recipientsName")}:
          </div>
          <p className="inline-flex mb-3">Narvi Payments Oy Ab</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("Narvi Payments Oy Ab")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.recipientsBankName")}:{" "}
          </div>
          <p className="inline-flex mb-3">Narvi Payments Oy Ab</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("Narvi Payments Oy Ab")}
          />
        </div>
      ) : currency === "SEK" ? (
        <div className="gap-1">
          <div className="text-sm text-gray-600">
            {t("moneyModal.accountNumber")}:
          </div>
          <p className="inline-flex mb-3">CH5108799927511655864</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("CH5108799927511655864")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.bicNumber")}:
          </div>
          <p className="inline-flex mb-3">INCOCHZZXXX</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("INCOCHZZXXX")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.message")}:
          </div>
          <p className="inline-flex mb-3">{portfolioShortName}</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard(String(portfolioShortName))}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.recipientsName")}:
          </div>
          <p className="inline-flex mb-3">Kvarn Capital Oy</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("Kvarn Capital Oy")}
          />
          <div className="text-sm text-gray-600">
            {t("moneyModal.recipientsBankName")}:{" "}
          </div>
          <p className="inline-flex mb-3">Narvi Payments Oy Ab</p>
          <CopyIcon
            width={20}
            height={20}
            className="inline-flex float-right mr-1 transition-transform active:scale-90 cursor-pointer"
            onClick={() => copyToClipboard("Narvi Payments Oy Ab")}
          />
        </div>
      ) : (
        <div>Unsupported currency</div>
      )}
    </div>
  );
};
