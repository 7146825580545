import { MutableRefObject } from "react";
import { useGetPortfolioFeeDetails } from "api/holdings/useGetPortfolioFeeDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";

interface DepositModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

interface XS2AOptions {
  onFinished: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void;
  onLoad: () => void;
  onClose: () => void;
  autoClose: boolean;
}

declare global {
  interface Window {
    XS2A: {
      startFlow: (clientToken: string, options: XS2AOptions) => void;
    };
    onXS2AReady: (response: ImportDepositQueryResponse) => void;
  }
}

interface ImportDepositQueryResponse {
  clientToken: string;
  extId: string;
  sessionId: string;
}

export const UserProfile = ({
  onClose,
  modalInitialFocusRef,
}: DepositModalProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  const contactData = useGetContactInfo(false, selectedContactId);

  const portfolioFees = useGetPortfolioFeeDetails(
    portfolios?.[0]?.id.toString()
  );

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <hr className="mb-3" />
      <div className="gap-1">
        <div className="text-sm text-gray-600">{t("profile.name")}:</div>
        <p className="inline-flex mb-3">{contactData?.data?.name}</p>

        <div className="text-sm text-gray-600">{t("profile.address")}:</div>
        <p className="inline-flex mb-3">
          {contactData?.data?.addresses?.[0]?.address1},{" "}
          {contactData?.data?.addresses?.[0]?.zipCode}{" "}
          {contactData?.data?.addresses?.[0]?.city}
        </p>
        <div className="text-sm text-gray-600">{t("profile.phone")}:</div>
        <p className="inline-flex mb-3">
          {contactData?.data?.addresses?.[0]?.phone1}
        </p>
        <div className="text-sm text-gray-600">{t("profile.email")}:</div>
        <p className="inline-flex mb-3">
          {contactData?.data?.addresses?.[0]?.email}
        </p>
        <div className="text-sm text-gray-600">{t("profile.feeLevel")}:</div>
        <p className="inline-flex mb-3">{portfolioFees?.data.feeLevelTag}</p>

        <div className="text-sm text-gray-600">
          {t("profile.feePercentage")}:
        </div>

        <p className="inline-flex mb-3">
          {((portfolioFees?.data.feePercentage || 0) * 100).toFixed(1)}
        </p>

        {portfolios?.[0]?.accounts?.[0].number && (
          <>
            <div className="text-sm text-gray-600">
              {t("profile.accountNumber")}:
            </div>
            <p className="inline-flex mb-3">
              {portfolios?.[0]?.accounts?.[0].number}
            </p>
          </>
        )}

        <div className="text-sm text-gray-600">
          {t("profile.cumulativeTradeAmount")}:
        </div>
        <p className="inline-flex mb-3">
          {portfolioFees?.data.cumulativeTradeAmount?.toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}
        </p>
        <hr className="my-1" />
        <div className="text-xs text-center text-gray-600 max-w-[375px]">
          {t("profile.info")}
        </div>
      </div>
    </div>
  );
};
