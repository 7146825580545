import { useEffect } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContactIdData } from "providers/ContactIdProvider";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any;
  }
}

export const ZendeskChat: React.FC = () => {
  const { selectedContactId } = useGetContactIdData();
  const contactData = useGetContactInfo(false, selectedContactId);

  const name = contactData?.data?.name;
  const email = contactData?.data?.addresses?.[0]?.email;

  const fullLanguage = contactData?.data?.locale ?? "fi";
  const language = fullLanguage.split("-")[0];

  useEffect(() => {
    if (name !== undefined && email !== undefined) {
      // Create a new script element
      const script = document.createElement("script");

      // Set the src attribute of the script element
      script.src = process.env.REACT_APP_ZENDESK_CHAT_URL ?? "";
      script.async = true;

      // Set the id attribute of the script element
      script.id = "ze-snippet";

      // Function to call when the script loads
      const onLoad = () => {
        window.zE("webWidget", "setLocale", language);
        window.zE("webWidget", "prefill", {
          name: {
            value: name,
            readOnly: true, // optional
          },
          email: {
            value: email,
            readOnly: true, // optional
          },
        });
      };

      // Add the 'load' event listener
      script.addEventListener("load", onLoad);

      // Append the script element to the document body
      document.body.appendChild(script);

      // Cleanup function to remove the script and the event listener when the component unmounts
      return () => {
        script.removeEventListener("load", onLoad);
        document.body.removeChild(script);
      };
    }
  }, [name, email, language]); // Empty dependency array ensures this runs once after initial render

  return null;
};
