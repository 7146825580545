import {
  SecurityCode,
  SecurityCodeWithoutTransferSupport,
} from "api/securities";
import { CheckMark } from "components/CheckMark/CheckMark";
import { getDecimalPlaces } from "../BuyModalContent/utils";

// SuccessMessageDisplay Component
interface SuccessMessageDisplayProps {
  response: {
    units: number;
    unitPrice: number;
    feePercentage: number;
    feeAmount: number;
    securityCode: SecurityCode | SecurityCodeWithoutTransferSupport;
  };
  t: (key: string, options?: Record<string, unknown> | undefined) => string;
  currency: string;
}

export const SuccessMessageDisplay: React.FC<SuccessMessageDisplayProps> = ({
  response,
  t,
  currency,
}) => {
  return (
    <div className="flex flex-col mx-auto mb-6 text-black">
      <CheckMark center />
      <div className="mt-4 mb-4 text-xl font-semibold text-center">
        {t("tradingModal.orderExecuted")}
      </div>
      {response && (
        <table className="table-auto">
          <tbody>
            <tr>
              <td className="py-2 px-4 border">{t("tradingModal.side")}</td>
              <td className="py-2 px-4 uppercase border">
                {t("tradingModal.typeSell")}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">{t("tradingModal.units")}</td>
              <td className="py-2 px-4 border">{response.units}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.transactionRate")}
              </td>
              <td className="py-2 px-4 border">
                {Number(response.unitPrice).toFixed(
                  getDecimalPlaces(response.securityCode)
                )}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.sellingPrice")}
              </td>
              <td className="py-2 px-4 border">
                {(Number(response.unitPrice) * response.units).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.feePercentage")}
              </td>
              <td className="py-2 px-4 border">
                {(response.feePercentage * 100).toFixed(1)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.feeAmount")} {currency}
              </td>
              <td className="py-2 px-4 border">
                - {Number(response.feeAmount).toFixed(2)}
              </td>
            </tr>
            {/* Calculate total by multiplying units * unitPrice + fee */}
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.total")} {currency}
              </td>
              <td className="py-2 px-4 border">
                {(
                  Number(response.units) * Number(response.unitPrice) -
                  Number(response.feeAmount)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
