import { gql, useQuery } from "@apollo/client";

interface OpenSellOrdersQuery {
  portfolio: {
    id: number;
    currency: {
      securityCode: string;
    };
    tradeOrders: Array<{
      id: number;
      amount: number;
      securityCode: string;
    }>;
  };
}

const OPEN_SELL_TRANSACTION_QUERY = gql`
  query GetSellData($portfolioId: Long) {
    portfolio(id: $portfolioId) {
      id
      currency {
        securityCode
      }
      tradeOrders(typeCodes: ["S"], orderStatus: "5") {
        id
        amount
        securityCode
      }
    }
  }
`;

export const useGetOpenPortfolioHoldingDetails = (
  portfolioId: string | undefined,
  securityCode: string | undefined
) => {
  const { loading, error, data, refetch } = useQuery<OpenSellOrdersQuery>(
    OPEN_SELL_TRANSACTION_QUERY,
    {
      variables: {
        portfolioId,
      },
      skip: !portfolioId,
      fetchPolicy: "network-only",
    }
  );

  const filteredOrders =
    data?.portfolio?.tradeOrders?.filter(
      (order) =>
        order.securityCode.toLowerCase() === securityCode?.toLowerCase()
    ) ?? [];

  const totalOpenSellOrders = filteredOrders.reduce(
    (acc, order) => acc + order.amount,
    0
  );

  return {
    loading,
    error,
    totalOpenSellOrders,
    refetch,
  };
};
