import { Button } from "components";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import {
  ONBOARDING_PROCESS_KEYS,
  OnboardingProcessKey,
} from "views/onboarding/helpers";

interface SelectOnboardingTypeProps {
  selectOnboardingProcessType: (key: OnboardingProcessKey) => void;
}

const ProcessKeyToTranslationKey: Record<OnboardingProcessKey, string> = {
  [ONBOARDING_PROCESS_KEYS.PRIVATE]: "private",
  [ONBOARDING_PROCESS_KEYS.BUSINESS]: "company",
};

export const SelectOnboardingType = ({
  selectOnboardingProcessType,
}: SelectOnboardingTypeProps) => {
  const { t } = useModifiedTranslation();

  const options = Object.values(ONBOARDING_PROCESS_KEYS).map((key) => {
    return {
      key,
      buttonLabel: t(`onboardingPage.${ProcessKeyToTranslationKey[key]}`),
    };
  });

  return (
    <div
      className="h-full"
      style={{
        backgroundImage: `url("/kvarn_forest.jpg")`, // Adjust the path as needed
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src="/logo_onboarding.png"
        alt="Kvarn X logo"
        className="p-4 pr-16 max-h-16 lg:ml-[calc(15%)]"
      ></img>
      <div className="flex justify-center items-center p-2 lg:mt-16">
        <div className="flex overflow-hidden flex-col gap-4 py-8 px-6 bg-white rounded-lg border shadow-md max-w-[680px]">
          <h1 className="mb-2 text-2xl">{t("onboardingPage.welcome")}</h1>
          <span>{t("onboardingPage.selectCustomerType")}</span>
          {options.map((option) => (
            <Button
              key={option.key}
              onClick={() => selectOnboardingProcessType(option.key)}
              className="max-w-4"
            >
              {option.buttonLabel}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
