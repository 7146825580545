import { useEffect, useState } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const getBackendTranslation = (
  fallbackTranslation: string,
  backendTranslationsMap: Record<string, string> = {},
  locale: string
) => {
  return (
    backendTranslationsMap[locale.replace("-", "_")] || fallbackTranslation
  );
};

type SupportedLanguage = "en-US" | "fi-FI" | "sv-SE";
type FormLanguage = "en" | "fi" | "sv";

export const SUPPORTED_LANGUAGES: SupportedLanguage[] = [
  "en-US",
  "fi-FI",
  "sv-SE",
];

const i18nLanguageToFormLanguage = (language: string): FormLanguage => {
  switch (language) {
    case "en-US":
      return "en";
    case "fi-FI":
      return "fi";
    case "sv-SE":
      return "sv";
    default:
      return "fi";
  }
};

export const useFormLanguage = (): FormLanguage => {
  const { i18n } = useModifiedTranslation();
  const [formLanguage, setFormLanguage] = useState<FormLanguage>("en");
  useEffect(() => {
    const language = i18nLanguageToFormLanguage(i18n.language);
    setFormLanguage(language);
  }, [i18n.language]);
  return formLanguage;
};

export const useFeedI18nextWithLocale = (locale: string | undefined) => {
  const [isReady, setIsReady] = useState(false);
  const { i18n } = useModifiedTranslation();

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale.replace("_", "-"), () => {
        setIsReady(true);
      });
    }
  }, [locale, i18n]);

  return { isReady };
};
