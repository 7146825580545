/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, RefObject } from "react";
import _ from "lodash";
import { FormError } from "./types";

const ONBOARDING_PROCESS_STORAGE_KEY = "onboardingProcessType";

export const ONBOARDING_PROCESS_KEYS = {
  PRIVATE: "yksityishenkiloOnboarding",
  BUSINESS: "yritysOnboarding",
};

// Typed as a value of the above object
export type OnboardingProcessKey =
  (typeof ONBOARDING_PROCESS_KEYS)[keyof typeof ONBOARDING_PROCESS_KEYS];

export const getOnboardingProcessType = () => {
  return localStorage.getItem(ONBOARDING_PROCESS_STORAGE_KEY);
};

export const storeOnboardingProcessType = (type: string) => {
  localStorage.setItem(ONBOARDING_PROCESS_STORAGE_KEY, type);
};

export const isBusinessOnboarding = () => {
  return getOnboardingProcessType() === ONBOARDING_PROCESS_KEYS.BUSINESS;
};

const CAMPAIGN_CODE_KEY = "campaignCode";

export const useCampaignCode = () => {
  const existingCampaignCode = window.localStorage.getItem(CAMPAIGN_CODE_KEY);
  if (existingCampaignCode) {
    return existingCampaignCode;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const newCampaignCode = urlParams.get(CAMPAIGN_CODE_KEY);
  if (newCampaignCode) {
    window.localStorage.setItem(CAMPAIGN_CODE_KEY, newCampaignCode);
  }
  return newCampaignCode;
};

/**
 * Hack around the fact the formio disables the submit on validation but
 * we want to let the user get the feedback from a failing submit.
 * @param formRef
 * @param onClickHandler
 * @returns
 */
export const replaceSubmitButton = (
  formRef: MutableRefObject<any>,
  onClickHandler: () => Promise<void>
) => {
  const submitButtonComponent = formRef.current.getComponent("submit");
  if (!submitButtonComponent || !submitButtonComponent.refs) {
    console.error("Submit button component not found.");
    return;
  }
  const buttonRef = submitButtonComponent.refs.button;
  buttonRef.style.display = "none";

  const newButton = document.createElement("button");
  newButton.innerHTML = buttonRef.innerHTML; // Set the text for the button
  newButton.className = buttonRef.className; // Copy classes from the original button
  newButton.name = buttonRef.name;
  newButton.type = buttonRef.type;

  newButton.onclick = () => onClickHandler();

  // Insert the new button after the original button
  buttonRef.parentNode.insertBefore(newButton, buttonRef.nextSibling);
};

export const LIST_ITEM_ID_PREFIX = "list-item-";

export const removeListItemElementById = (
  parentElement: HTMLElement,
  id: string
) => {
  const child = parentElement.querySelector(`#${LIST_ITEM_ID_PREFIX}${id}`);
  if (child) {
    parentElement.removeChild(child);
  }
};

export const scrollElementIntoView = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const addErrorListItems = (
  parentRef: RefObject<HTMLDivElement>,
  errors: FormError[]
) => {
  if (!parentRef.current) return;
  // Remove all existing error messages
  while (parentRef.current.firstChild) {
    parentRef.current.removeChild(parentRef.current.firstChild);
  }
  const typedErrors = errors as FormError[];
  typedErrors.forEach((err: FormError) => {
    const errorMessageElement = document.createElement("li");
    errorMessageElement.id = `${LIST_ITEM_ID_PREFIX}${err.component.id}`;
    errorMessageElement.textContent = err.message;
    // note we scroll to the source of the error, not the one we're creating here
    errorMessageElement.onclick = () => scrollElementIntoView(err.component.id);
    if (parentRef.current) {
      parentRef.current.appendChild(errorMessageElement);
    }
  });
};

/**
 * Helper to set a nested object value using dot notation.
 * Form is stored as dot notation in localStorage so we use this
 * to convert it to a nested object when loading from storage.
 */
export const dotNotationToNestedObject = (target: any, source: any) => {
  if (
    !target ||
    !source ||
    !Object.keys(target).length ||
    !Object.keys(source).length
  ) {
    return target;
  }
  Object.keys(source).forEach((key) => {
    if (!key?.split) return;
    // Convert the dot notation to an array path.
    const path = key.split(".");
    // Use lodash's set method to assign the value.
    _.set(target, path, source[key]);
  });
};
