import { MutableRefObject, useState, useEffect } from "react";
import { SecurityTradeType } from "api/holdings/types";
import { useGetAllPortfolioHoldingDetails } from "api/holdings/useGetAllPortfolioHoldingDetails";
import { useGetOpenPortfolioHoldingDetails } from "api/holdings/useGetOpenPortfolioHoldingDetails";
import { useGetPortfolioFeeDetails } from "api/holdings/useGetPortfolioFeeDetails";
import { useGetPortfolioHoldingDetails } from "api/holdings/useGetPortfolioHoldingDetails";
import { useGetPortfolioHoldings } from "api/holdings/useGetPortfolioHoldings";
import { useGetStakingHoldingDetails } from "api/holdings/useStakingDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { convertSecurityCodeSekToSecurityCode } from "api/securities";
import {
  SecurityCodeSek,
  SecurityCode,
  SecurityCodeWithoutTransferSupport,
} from "api/securities";
import { useCryptoTrade } from "api/trading/useCryptoTrade";
import { useGetBuyData } from "api/trading/useGetBuyData";
import InfoIcon from "assets/info-icon.svg";
import {
  PortfolioSelect,
  DownloadableDocument,
  Button,
  Input,
  LabeledDiv,
} from "components/index";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CONTAINERS, INPUTS } from "testIds";
import { round } from "utils/number";
import { ErrorMessageDisplay } from "./ErrorMessageDisplay";
import { OpenOrderMessageDisplay } from "./OpenOrderMessageDisplay";
import { SuccessMessageDisplay } from "./SuccessMessageDisplay";
import { useTradeAmountInput } from "./useTradeAmountInput";
import { useGetSecurityDetails } from "../../../api/holdings/useGetSecurityDetails";
import useCryptoMinMax from "../../../api/minmax/useFetchMinMax";
import useFetchQuotes from "../../../api/prices/useFetchPrices";
import { Chart } from "../BuyModalContent/Chart";
import { ToggleSwitch } from "../BuyModalContent/toggle";
import { getDecimalPlaces } from "../BuyModalContent/utils";
import { validateUnitsTrade } from "../BuyModalContent/utils";
import { isLimitOrderOnly } from "../BuyModalContent/utils";
import { useTradablePortfolioSelect } from "../useTradablePortfolioSelect";

export interface SellModalInitialData {
  id: number;
}

interface SellModalProps extends SellModalInitialData {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
  onTradeCompletion?: () => void;
}

const getCurrentAmount = (
  isTradeInUnits: boolean | undefined,
  amount: number,
  marketValue: number,
  marketFxRate: number
) => (isTradeInUnits ? amount : round(marketValue * marketFxRate, 2));

const getTradeAmount = (
  isTradeInUnits: boolean | undefined,
  tradeAmount: number,
  price: number,
  selectedOption: string,
  limitOrderPrice: string
) => {
  if (selectedOption === "limitOrder") {
    return Number(limitOrderPrice) * tradeAmount;
  } else {
    return isTradeInUnits ? tradeAmount * price : tradeAmount;
  }
};

export const SellModalContent = ({
  modalInitialFocusRef,
  id: securityId,
}: SellModalProps) => {
  const {
    data: security = {
      name: "",
      url2: undefined,
      type: { code: undefined },
      latestMarketData: undefined,
      fxRate: 1,
      securityCode: "",
      currency: { securityCode: "" },
      tagsAsSet: [],
    },
  } = useGetSecurityDetails(securityId.toString());

  const {
    name: securityName,
    currency: { securityCode: currency },
    url2,
    type: { code: securityType } = {},
    tagsAsSet: securityTags,
  } = security;

  const [isTradeInUnits, setIsTradeInUnits] = useState(true);
  const [canToggleTradeType, setCanToggleTradeType] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("marketOrder");
  const [limitOrderPrice, setLimitOrderPrice] = useState("");
  const [allowedSlippage, setAllowedSlippage] = useState<number>(0.997);

  useEffect(() => {
    const isTradeTypeSpecified = securityTags?.some(
      (tag) =>
        tag === SecurityTradeType.units || tag === SecurityTradeType.tradeAmount
    );
    const isUnitsSupported = securityTags?.some(
      (tag) => tag === SecurityTradeType.units
    );
    const isTradeAmountSupported = securityTags?.some(
      (tag) => tag === SecurityTradeType.tradeAmount
    );
    const isUnitsDefaultTradeType = true; //always true when selling
    setCanToggleTradeType(
      isTradeTypeSpecified && isUnitsSupported && isTradeAmountSupported
    );
    setIsTradeInUnits(
      isTradeTypeSpecified ? isUnitsSupported : isUnitsDefaultTradeType
    );
  }, [securityTags, securityType]);

  useEffect(() => {
    if (
      security.securityCode &&
      isLimitOrderOnly(
        security.securityCode as
          | SecurityCode
          | SecurityCodeWithoutTransferSupport
      )
    ) {
      setSelectedOption("limitOrder");
    }
  }, [security.securityCode]);

  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();

  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const { portfolioId, setPortfolioId, portfolioOptions } =
    useTradablePortfolioSelect();

  const {
    loading,
    data: { marketValue = 0, marketFxRate = 1, amount: units = 0 } = {},
  } = useGetPortfolioHoldingDetails(
    portfolioId.toString(),
    securityId.toString(),
    false
  );
  const currentAmount = getCurrentAmount(
    isTradeInUnits,
    units,
    marketValue,
    marketFxRate
  );

  const { data: { currency: portfolioCurrency = "EUR" } = {} } = useGetBuyData(
    portfolioId.toString()
  );

  const { totalOpenSellOrders } = useGetOpenPortfolioHoldingDetails(
    portfolioId.toString(),
    security.securityCode
  );

  // WIP
  const { totalStakeAmount } = useGetStakingHoldingDetails(
    portfolioId.toString(),
    security.securityCode
  );

  const currentAmountAdjustedForOpenOrders =
    currentAmount - totalOpenSellOrders - totalStakeAmount;

  const quotesData = useFetchQuotes(portfolioCurrency as "EUR" | "SEK");

  const {
    inputValue,
    setInputState,
    inputModesOptions,
    inputMode,
    isTradeAmountCorrect,
    amount,
    setTradeAmountToAll,
    setTradeAmountToHalf,
    onInputModeChange,
  } = useTradeAmountInput(
    currentAmountAdjustedForOpenOrders,
    currency,
    isTradeInUnits
  );

  const { refetch } = useGetBuyData(portfolioId.toString());

  const foundPortfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId
  );
  const foundPortfolioShortName =
    (foundPortfolio ? foundPortfolio.shortName : portfolios[0].shortName) || "";
  const portfolioShortName = foundPortfolioShortName.toString() || "";

  // Set state for success message and error message
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [openTradeMessageVisible, setOpenTradeMessageVisible] = useState(false);
  const [inputError, setInputError] = useState("");
  const [formError, setFormError] = useState("");

  // Calculate unitPrice by multiplying the price of the security by the 50 basis points and convert to string
  const unitPriceWithSlippage = (
    Number(quotesData[security.name]?.sell) * allowedSlippage
  ).toFixed(getDecimalPlaces(security.securityCode));

  // Convert selectedContactId to sting
  const sContactId = selectedContactId.toString();

  const orderType = selectedOption === "limitOrder" ? "Limit" : "Market";

  const [isEnabled, setIsEnabled] = useState(false);
  const [endTime, setEndTime] = useState<string>("");

  const handleToggle = (newValue: boolean) => {
    setIsEnabled(newValue);
  };

  const {
    handleTrade: handleSell,
    submitting,
    response,
  } = useCryptoTrade({
    portfolioShortName,
    units: amount,
    unitPrice:
      selectedOption === "limitOrder" ? limitOrderPrice : unitPriceWithSlippage,
    transactionTypeCode: "S",
    contact: sContactId,
    securityCode: security.securityCode,
    currency: security.currency.securityCode,
    dryRun: false,
    orderType: orderType,
    ...(selectedOption === "limitOrder" &&
      isEnabled &&
      endTime && { endTime: new Date(endTime).getTime() }),
  });

  // Handle success message
  const handleSuccessfulTrade = () => {
    setSuccessMessageVisible(true);
  };

  // Handle open trade
  const handleOpenTrade = () => {
    setOpenTradeMessageVisible(true);
  };

  // Get min and max prices from B2C2 api using useFetchMinMax hook
  const minMaxData = useCryptoMinMax();

  // Get feePercentage
  const { data: { feePercentage = 0 } = {} } =
    useGetPortfolioFeeDetails(portfolioId.toString()) || {};

  // Calculate fee if less than 1 EUR or 10 SEK then set to 1 EUR or 10 SEK
  const minFee = portfolioCurrency === "SEK" ? 10 : 1;

  const tentativeFee = Math.max(
    Number(
      selectedOption === "limitOrder"
        ? limitOrderPrice
        : quotesData[security.name]?.sell || 0
    ) *
      Number(amount) *
      Number(feePercentage),
    minFee
  ).toFixed(2);

  // refetch holdings after a successful trade
  const { refetch: refetchHolding } = useGetPortfolioHoldings(
    portfolioId.toString()
  );

  const { refetch: refetchHoldingPositions } = useGetAllPortfolioHoldingDetails(
    portfolioId.toString()
  );

  const handleSellClick = async () => {
    setFormError("");
    const response = await handleSell();
    if (response.status === "executed") {
      handleSuccessfulTrade();
    } else if (response.status === "accepted") {
      handleOpenTrade();
    } else {
      const errorMessageKey =
        response.message === "Insufficient funds" ||
        response.message === "Insufficient units"
          ? "tradingModal.insufficientFunds"
          : "tradingModal.priceMovement";
      setFormError(t(errorMessageKey));
    }
  };

  // TODO - Refactor this. If delay is removed modal sometimes closes before close button is pressed
  useEffect(() => {
    if (successMessageVisible) {
      const timer = setTimeout(() => {
        refetch();
        refetchHolding();
        refetchHoldingPositions();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [successMessageVisible, refetch, refetchHolding, refetchHoldingPositions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target?.value;
    const parsedValue = Number(value);
    const isValidNumber = !isNaN(parsedValue) && parsedValue >= 0;

    validateUnitsTrade(value, setInputError, t, minMaxData[security.name]);

    setInputState((previousState) => ({
      ...previousState,
      inputValue: isValidNumber ? value : previousState.inputValue,
    }));
  };

  const securityCodeWithoutSek =
    portfolioCurrency === "SEK"
      ? convertSecurityCodeSekToSecurityCode(
          security.securityCode as SecurityCodeSek
        )
      : security.securityCode;

  const minSizeIncrement = getDecimalPlaces(
    minMaxData[security.name]?.minSizeIncrement
  );

  const [allowedSlippageInput, setAllowedSlippageInput] = useState<string>(
    Math.abs((allowedSlippage - 1) * 100).toFixed(2)
  );

  const handleSlippageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAllowedSlippageInput(inputValue); // Update input field directly
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      setAllowedSlippage(1 - Math.abs(numericValue / 100)); // Update state only with valid number
    }
  };

  return (
    <div className="grid lg:grid-cols-4 gap-2 min-h-[650px]">
      <div className="hidden lg:block lg:col-span-3">
        <Chart
          securityCode={securityCodeWithoutSek}
          currency={portfolioCurrency}
        />
      </div>
      <div
        className="lg:col-span-1 lg:ml-8"
        data-testid={CONTAINERS.SELL_MODAL}
      >
        {!successMessageVisible && !openTradeMessageVisible ? (
          <>
            <LabeledDiv
              label={t("tradingModal.securityName")}
              className="text-2xl font-semibold"
            >
              {securityName}
            </LabeledDiv>
            {url2 && (
              <div className="w-fit">
                <DownloadableDocument
                  url={url2}
                  label={t("tradingModal.kiid")}
                />
              </div>
            )}
            <PortfolioSelect
              portfolioOptions={portfolioOptions}
              portfolioId={portfolioId}
              onChange={(newPortfolio) => setPortfolioId(newPortfolio.id)}
              label={t("tradingModal.portfolio")}
            />
            <div className="my-4">
              <label
                htmlFor="tradeOption"
                className="block text-sm font-medium text-gray-700"
              >
                {t("tradingModal.strategy")}
              </label>
              {isLimitOrderOnly(
                security.securityCode as
                  | SecurityCode
                  | SecurityCodeWithoutTransferSupport
              ) ? (
                <div className="py-2 px-3 mt-1 text-base sm:text-sm text-gray-700 bg-gray-100 rounded-md">
                  {t("tradingModal.limitOrder")}
                </div>
              ) : (
                <select
                  id="tradeOption"
                  name="tradeOption"
                  className="block py-2 pr-10 pl-3 mt-1 w-full text-base sm:text-sm rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                  value={selectedOption}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setLimitOrderPrice("");
                    setInputState((prevState) => ({
                      ...prevState,
                      inputValue: "",
                    }));
                  }}
                >
                  <option value="marketOrder">
                    {t("tradingModal.marketOrderSell")}
                  </option>
                  <option value="limitOrder">
                    {t("tradingModal.limitOrder")}
                  </option>
                </select>
              )}
            </div>
            {isTradeInUnits ? (
              <LabeledDiv
                label={t("tradingModal.currentUnits")}
                className="mb-4 text-xl font-semibold text-gray-700"
              >
                {currency &&
                  t("number", {
                    value: currentAmountAdjustedForOpenOrders,
                  })}
              </LabeledDiv>
            ) : (
              <LabeledDiv
                label={t("tradingModal.currentMarketValue")}
                className="text-xl font-semibold text-gray-700"
              >
                {currency &&
                  t("numberWithCurrency", {
                    value: currentAmount,
                    currency: currency,
                  })}
              </LabeledDiv>
            )}
            <Input
              data-testid={INPUTS.TRADE_AMOUNT}
              ref={modalInitialFocusRef}
              value={inputValue || ""}
              onChange={handleInputChange}
              label={
                isTradeInUnits
                  ? t("tradingModal.unitsInputLabel")
                  : t("tradingModal.tradeAmountInputLabel", {
                      currency: inputMode.label,
                    })
              }
              type="number"
              min="0"
              step="any"
              error={
                !isTradeAmountCorrect && !loading
                  ? t("tradingModal.tradeAmountInputError")
                  : inputError
              }
            />
            {selectedOption === "limitOrder" && (
              <>
                <Input
                  value={limitOrderPrice}
                  onChange={(event) =>
                    setLimitOrderPrice(event.currentTarget.value)
                  }
                  label={t("tradingModal.limitPrice")}
                  type="number"
                />
                <div className="flex justify-between items-center mt-4 text-sm font-normal">
                  <p>{t("tradingModal.toggleLabel")}</p>
                  <ToggleSwitch enabled={isEnabled} setEnabled={handleToggle} />
                </div>
                {isEnabled && (
                  <>
                    <div className="mt-4">
                      <label
                        htmlFor="endTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("tradingModal.endTime")}{" "}
                        <img
                          src={InfoIcon}
                          data-tooltip-id="end-time-tooltip"
                          data-tooltip-html={t("tradingModal.endTimeInfo")}
                          alt="Info"
                          className="inline ml-1 w-4 h-4"
                        />
                      </label>
                      <ReactTooltip
                        id="end-time-tooltip"
                        place="bottom"
                        variant="dark"
                      />
                      <input
                        type="datetime-local"
                        id="endTime"
                        name="endTime"
                        className="block py-2 pr-10 pl-3 mt-1 w-full text-base sm:text-sm rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                        value={endTime.slice(0, 16)}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </div>
                    <hr className="mt-4 border-t border-gray-200" />
                  </>
                )}
              </>
            )}
            {selectedOption === "marketOrder" && (
              <div className="flex justify-between items-center mt-4 text-sm font-normal">
                <p>{t("tradingModal.toggleLabel")}</p>
                <ToggleSwitch enabled={isEnabled} setEnabled={handleToggle} />
              </div>
            )}
            {isEnabled && selectedOption === "marketOrder" && (
              <>
                <div className="flex gap-8 items-center mt-4 text-sm font-normal">
                  <label
                    htmlFor="allowedSlippage"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("tradingModal.allowedSlippage")}{" "}
                    <img
                      src={InfoIcon}
                      data-tooltip-id="my-tooltip-2"
                      data-tooltip-html={t("tradingModal.allowedSlippageInfo")}
                      alt="Info"
                      className="inline ml-1 w-4 h-4"
                    />
                  </label>
                  <ReactTooltip
                    id="my-tooltip-2"
                    place="bottom"
                    variant="dark"
                  />
                  <div className="flex mt-2 ml-auto">
                    <Input
                      id="allowedSlippage"
                      label=""
                      name="allowedSlippage"
                      type="number"
                      step="0.01"
                      min="0"
                      value={allowedSlippageInput}
                      onChange={handleSlippageChange}
                      className="block py-2 my-2 w-20 text-base sm:text-sm text-center rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="inline-flex mt-4 text-sm font-normal">
                  <div>
                    {t("tradingModal.minPriceWithSlippage")}:{" "}
                    {unitPriceWithSlippage}{" "}
                    {portfolioCurrency === "SEK" ? "kr" : "EUR"}
                  </div>
                </div>
              </>
            )}
            <div className="mt-4 text-sm font-normal">
              <div>
                {t("tradingModal.totalHoldingsAmount")}: {currentAmount}
              </div>
            </div>
            <div className="inline-flex gap-8 mt-4 text-sm font-normal">
              <div>Min: {minMaxData[security.name]?.minimumSize}</div>{" "}
              <div> Max: {minMaxData[security.name]?.maximumSize}</div>
            </div>
            <div className="flex flex-col text-sm font-normal">
              {t("tradingModal.indicativeFee")}: {tentativeFee} {currency}
            </div>
            <div className="flex flex-col mb-4 text-sm font-normal">
              {t("tradingModal.feePercentage")}:{" "}
              {(feePercentage * 100).toFixed(1)}
            </div>
            {canToggleTradeType && (
              <>
                <div className="flex overflow-hidden font-medium leading-5 bg-gray-50 rounded-md divide-x ring-1 shadow-sm pointer-events-auto select-none divide-slate-400/20 text-[0.8125rem] ring-slate-700/10">
                  <button
                    className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                      isTradeInUnits ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setIsTradeInUnits(true)}
                  >
                    {t("tradingModal.unitsButtonLabel")}
                  </button>

                  <button
                    className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                      !isTradeInUnits ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setIsTradeInUnits(false)}
                  >
                    {t("tradingModal.tradeAmountButtonLabel")}
                  </button>
                </div>
              </>
            )}

            <div className="flex justify-between items-center h-8">
              <div className="flex gap-1 items-center">
                <Button
                  size="xs"
                  variant="Red"
                  onClick={setTradeAmountToAll(minSizeIncrement)}
                >
                  {t("tradingModal.sellAll")}
                </Button>
                {!isTradeInUnits && (
                  <Button
                    size="xs"
                    variant="Secondary"
                    onClick={setTradeAmountToHalf}
                  >
                    {t("tradingModal.sellHalf")}
                  </Button>
                )}
              </div>
              {!isTradeInUnits && (
                <div className="flex flex-row gap-2 p-1 text-sm bg-gray-50 rounded-lg border border-gray-300 select-none">
                  {inputModesOptions.map((option) => (
                    <label
                      htmlFor={option.id}
                      key={option.id}
                      className={`cursor-pointer flex flex-row gap-x-1 justify-center items-center px-3 rounded-lg ${
                        inputMode.id === option.id
                          ? "ring-2 ring-primary-600 bg-primary-50"
                          : ""
                      }`}
                    >
                      <span className="text-sm cursor-pointer">
                        {option.label}
                      </span>
                      <input
                        type={"radio"}
                        className="absolute opacity-0 cursor-pointer"
                        id={option.id}
                        name={option.label}
                        checked={inputMode.id === option.id}
                        onChange={() =>
                          onInputModeChange({
                            id: option.id,
                            label: option.label,
                          })
                        }
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>
            <hr />
            <div className="flex flex-col gap-4 items-stretch ">
              <div className="text-3xl font-semibold text-center">
                <div className="text-base font-normal">
                  {t("tradingModal.unitPrice")}
                </div>
                {Number(quotesData[security.name]?.sell || 0).toFixed(
                  getDecimalPlaces(security.securityCode)
                )}{" "}
                {currency}
                <div className="mt-4 text-base font-normal">
                  {t("tradingModal.tradeAmount")}
                </div>
                {t("numberWithCurrency", {
                  value: isTradeAmountCorrect
                    ? getTradeAmount(
                        isTradeInUnits,
                        Number(amount),
                        Number(quotesData[security.name]?.sell || 0),
                        selectedOption,
                        limitOrderPrice
                      )
                    : 0,
                  currency,
                })}
              </div>
              <Button
                disabled={
                  amount === 0 ||
                  loading ||
                  !isTradeAmountCorrect ||
                  (selectedOption === "limitOrder" && limitOrderPrice === "")
                }
                isLoading={submitting}
                variant="Red"
                onClick={handleSellClick}
              >
                {t("tradingModal.sellModalHeader")}
              </Button>
            </div>
          </>
        ) : openTradeMessageVisible ? (
          <OpenOrderMessageDisplay
            t={t}
            response={response}
            limitOrderPrice={limitOrderPrice}
            tentativeFee={tentativeFee}
            currency={portfolioCurrency}
          />
        ) : successMessageVisible && response ? (
          <SuccessMessageDisplay
            response={{
              ...response,
              unitPrice: Number(response.unitPrice),
              securityCode: security.securityCode as
                | SecurityCode
                | SecurityCodeWithoutTransferSupport,
            }}
            t={t}
            currency={currency}
          />
        ) : null}
        {formError && <ErrorMessageDisplay errorMessage={formError} t={t} />}
        <hr className="my-1" />
        <div className="text-xs text-center text-gray-600 max-w-[375px]">
          {t("tradingModal.buyDisclaimer")}
        </div>
      </div>
    </div>
  );
};
