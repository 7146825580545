const LANGUAGE_MAPPING = {
  fi: "Finnish",
  sv: "Swedish",
  en: "English",
};

export const triggerOnboardingEmail = async (
  emailAddress: string,
  language: keyof typeof LANGUAGE_MAPPING,
  onboardingType: "private" | "company" | "public",
  marketingConsent?: boolean,
  firstName?: string,
  lastName?: string,
  phone?: string
) => {
  if (!emailAddress) {
    console.warn(
      `Falsy email address: ${emailAddress}. Skipping email sending.`
    );
    return;
  }
  const { REACT_APP_EMAIL_API_URL } = process.env;
  if (!REACT_APP_EMAIL_API_URL) {
    console.warn("REACT_APP_EMAIL_API_URL not set, skipping email sending.");
    return;
  }

  // Convert boolean marketingConsent to "yes" or "no"
  const marketingConsentStr = marketingConsent ? "yes" : "no";

  const fullLanguageName = LANGUAGE_MAPPING[language];
  try {
    // TODO: Mobile app?
    await fetch(REACT_APP_EMAIL_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailAddress,
        language: fullLanguageName,
        marketingConsent: marketingConsentStr,
        type: onboardingType,
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(phone && { phone }),
      }),
    });
    console.log("Email sent successfully.");
  } catch (error) {
    console.error("Failed to send email: ");
  }
};
