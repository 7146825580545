import { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { getCoinbaseName } from "./utils";

interface ChartProps {
  securityCode: string;
  currency: string; // Add a currency prop
}

export const Chart = ({ securityCode, currency }: ChartProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const chart = useMemo(() => {
    if (!securityCode || !isMounted || currency === "SEK") return null;

    return (
      <ErrorBoundary>
        <AdvancedRealTimeChart
          symbol={getCoinbaseName(securityCode)}
          autosize
          interval="D"
        />
      </ErrorBoundary>
    );
  }, [securityCode, isMounted, currency]);

  return chart;
};
