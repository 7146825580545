import { useState } from "react";

interface RedirectResponse {
  url: string;
}

export const useGetRedirectUrl = () => {
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState<RedirectResponse>();

  const getRedirectUrl = async () => {
    try {
      setSubmitting(true);

      const response = await fetch(
        process.env.REACT_APP_ONFIDO_FLOWS_API_URL || "",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setResponse(result);

      setSubmitting(false);

      return result;
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  return { getRedirectUrl, submitting, response };
};
