import { Button } from "components";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

const appUrl = "https://app.kvarnx.com";

export const DepositUnavailableInApp = () => {
  const { t } = useModifiedTranslation();

  const handleOpenInBrowser = () => {
    window.open(appUrl, "_blank");
  };

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <p className="py-4 text-sm font-semibold text-gray-500 max-w-[375px]">
        {" "}
        {t("moneyModal.depositUnavailableDisclaimer")}
      </p>
      <div className="flex flex-col gap-4 items-stretch ">
        <Button onClick={handleOpenInBrowser}>
          {t("moneyModal.openInBrowserButtonLabel")}
        </Button>
      </div>
      <hr className="my-1" />
    </div>
  );
};
