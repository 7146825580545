import { gql, useQuery } from "@apollo/client";
import { PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS } from "./fragments";
import { PortfolioHoldingDetailsQuery } from "./types";

const HOLDING_DETAILS_QUERY = gql`
  ${PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS}
  query GetPortfolioHoldingDetails(
    $portfolioId: Long
    $adjustPositionsBasedOnOpenTradeOrders: Boolean!
  ) {
    portfolio(id: $portfolioId) {
      id
      portfolioReport(
        adjustPositionsBasedOnOpenTradeOrders: $adjustPositionsBasedOnOpenTradeOrders
        calculateExpectedAmountBasedOpenTradeOrders: $adjustPositionsBasedOnOpenTradeOrders
        excludeTradeOrderStatusOpen: true
      ) {
        portfolioId
        ...PortfolioReportHoldingDetailsFields
      }
    }
  }
`;

export const useGetPortfolioHoldingDetails = (
  portfolioId: string | undefined,
  securityId: string | undefined,
  adjustPositions: boolean
) => {
  const { loading, error, data, refetch } =
    useQuery<PortfolioHoldingDetailsQuery>(HOLDING_DETAILS_QUERY, {
      variables: {
        portfolioId,
        adjustPositionsBasedOnOpenTradeOrders: adjustPositions,
      },
      fetchPolicy: "network-only",
    });

  return {
    loading,
    error,
    data: data?.portfolio.portfolioReport.holdingPositions?.find(
      (holding) => holding.security.id.toString() === securityId
    ),
    refetch,
  };
};
