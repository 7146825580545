import { gql, useQuery } from "@apollo/client";
import { PortfolioFeesQuery } from "./types";

const FEES_QUERY = gql`
  query GetPortfolioFees($portfolioIds: [String!]) {
    portfolios(ids: $portfolioIds) {
      id
      profileDataString
    }
  }
`;

interface FeeDetails {
  calculationMethod?: string;
  feeLevelTag?: string;
  feePercentage?: number;
  cumulativeTradeAmount?: number;
  observationPeriod?: string;
}

function extractFeeDetails(profileDataString: string): FeeDetails {
  const lines = profileDataString.split("\n");
  const feeDetails: FeeDetails = {};

  lines.forEach((line) => {
    const [key, value] = line.split("=");

    if (key.includes("portfolio.feelevel.calculationmethod")) {
      feeDetails.calculationMethod = value;
    } else if (key.includes("portfolio.feelevel.feeleveltag")) {
      feeDetails.feeLevelTag = value.replace(/,$/, "");
    } else if (key.includes("portfolio.feelevel.feepercentage")) {
      feeDetails.feePercentage = parseFloat(value);
    } else if (key.includes("portfolio.feelevel.cumulativetradeamount")) {
      feeDetails.cumulativeTradeAmount = parseFloat(value);
    } else if (key.includes("portfolio.feelevel.observationperiod")) {
      feeDetails.observationPeriod = value;
    }
  });

  return feeDetails;
}

export const useGetPortfolioFeeDetails = (portfolioId: string | undefined) => {
  const { loading, error, data, refetch } = useQuery<PortfolioFeesQuery>(
    FEES_QUERY,
    {
      variables: {
        portfolioIds: portfolioId ? [portfolioId] : [],
      },
      fetchPolicy: "network-only",
    }
  );

  const profileDataString = data?.portfolios?.[0]?.profileDataString;
  const feeDetails = profileDataString
    ? extractFeeDetails(profileDataString)
    : {};

  return {
    loading,
    error,
    data: feeDetails,
    refetch,
  };
};
