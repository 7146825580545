import React, { useEffect } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { keycloakService } from "services/keycloakService";

interface ImportDepositQueryResponseFinsharkPut {
  id: string;
  status: string;
}

interface QueryParams {
  [key: string]: string | undefined;
}

const updateTransferStatus = async (
  response: ImportDepositQueryResponseFinsharkPut
) => {
  const apiUrl = process.env.REACT_APP_TRANSFER_API_URL || "";
  if (!apiUrl) {
    throw new Error(
      "REACT_APP_TRANSFER_API_URL environment variable is not set"
    );
  }

  const putData = {
    id: response.id,
    status: response.status,
  };

  const authToken = await keycloakService.getToken();

  try {
    const apiResponse = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(putData),
    });

    if (!apiResponse.ok) {
      throw new Error(`API call failed with status ${apiResponse.status}`);
    }
  } catch (error) {
    console.error("PUT API call error:", error);
  }
};

// These exist in fail and success results
const GUARANTEED_PARAMS = ["authorizationId", "sessionId", "result"];
const ERROR_PARAMS = ["message"];
const ALL_PARAMS = [...GUARANTEED_PARAMS, ...ERROR_PARAMS];

const parseQueryParams = (searchString: string): QueryParams => {
  return searchString
    .substring(1) // Remove the '?' at the beginning
    .split("&") // Split at each '&'
    .reduce((acc: QueryParams, current: string) => {
      const [key, value] = current.split("=");
      // Only add to acc if key is in predefinedParams
      if (ALL_PARAMS.includes(key)) {
        acc[key] = value;
      }
      return acc;
    }, {});
};

export const FinsharkCallbackListener: React.FC = () => {
  const location = useLocation();
  const { t } = useModifiedTranslation();

  useEffect(() => {
    const queryParams = parseQueryParams(location.search);

    const isFinnsharkCallback = GUARANTEED_PARAMS.every((param) => {
      return Object.keys(queryParams).includes(param);
    });

    if (!isFinnsharkCallback) return;

    const isError = queryParams.result === "error";
    const isSuccess = queryParams.result === "success";

    if (isError) {
      toast.error(t("messages.depositFail"));
      console.error("Finshark callback error:", queryParams.message);
      return;
    }

    if (isSuccess) {
      const response: ImportDepositQueryResponseFinsharkPut = {
        id: queryParams.paymentId as string,
        status: "COMPLETED",
      };
      updateTransferStatus(response);
      toast.success(t("messages.depositSuccess"));
      return;
    }
  }, [location, t]);

  return null; // This component does not render anything
};
