import { Portfolio } from "api/initial/useGetContactInfo";

export const TransferOrderPermissionGroup = "CP_TRANSFER" as const;

export const isPortfolioAllowedToTransfer = (portfolio?: Portfolio) => {
  if (!portfolio || !portfolio.portfolioGroups) return false;
  return portfolio.portfolioGroups.some(
    (group) => (group.code as string) === TransferOrderPermissionGroup
  );
};
