import { useEffect } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation } from "react-router-dom";
import { OnboardingFooter } from "./OnboardingFooter";
import { OnboardingHeader } from "./OnboardingHeader";
import backgroundImage from "../../../../src/assets/images/kvarn_forest.jpg";

export const WaitPortfolioActivationInfo = () => {
  const { t, i18n } = useModifiedTranslation();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get("lang");
    if (lang && i18n) {
      i18n.changeLanguage(lang);
    }
  }, [location, i18n]);

  return (
    <div className="flex flex-col min-h-[100vh]">
      <div
        className="flex-grow"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <OnboardingHeader hideLangSelection />
        <div className="flex justify-center w-full">
          <div className="flex flex-wrap justify-center w-full lg:max-w-[600px]">
            <div className="p-8 px-4 pt-2 mt-16 mb-8 w-full bg-kvarn-dark-green rounded-lg h-fit">
              <h1 className="p-2 text-2xl leading-normal text-kvarn-light-green">
                {t("waitPortfolioActivation.title")}{" "}
              </h1>{" "}
              <p className="p-2 font-thin leading-normal text-white">
                {t("waitPortfolioActivation.text")}{" "}
                <a
                  href="https://www.kvarnx.com/"
                  className="text-kvarn-light-green hover:underline"
                >
                  {t("waitPortfolioActivation.linkText")}.
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <OnboardingFooter />
    </div>
  );
};
