import { MutableRefObject, useState } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useKlarnaDeposit } from "api/money/useKlarnaDeposit";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { Input, Button } from "components";
import { isMobileApp } from "config";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import i18n from "i18next";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { keycloakService } from "services/keycloakService";
import { DepositUnavailableInApp } from "./DepositUnavailableInApp";
import { useDepositablePortfolioSelect } from "./useDepositablePortfolioSelect";
import { CashAccountSelect } from "../components/CashAccountSelect";
import { usePortfoliosAccountsState } from "../usePortfoliosAccountsState";

interface DepositModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

interface XS2AOptions {
  onFinished: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void;
  onLoad: () => void;
  onClose: () => void;
  autoClose: boolean;
}

declare global {
  interface Window {
    XS2A: {
      startFlow: (clientToken: string, options: XS2AOptions) => void;
    };
    onXS2AReady: (response: ImportDepositQueryResponse) => void;
  }
}

interface ImportDepositQueryResponse {
  clientToken: string;
  extId: string;
  sessionId: string;
}

export const DepositModalContent = ({
  onClose,
  modalInitialFocusRef,
}: DepositModalProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const portfolioSelectProps = useDepositablePortfolioSelect();
  const { portfolioId } = portfolioSelectProps;

  const { accountsLoading, ...cashAccountSelectProps } =
    usePortfoliosAccountsState(portfolioId);
  const {
    currentCashAccount: { availableBalance = 0, currency = "EUR" } = {},
  } = cashAccountSelectProps;

  const [amount, setAmount] = useState(0);

  const isAmountCorrect = !isNaN(availableBalance) && amount >= 0;

  const foundPortfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId
  );
  const foundPortfolioShortName =
    (foundPortfolio ? foundPortfolio.shortName : portfolios[0].shortName) || "";
  const portfolioShortName = foundPortfolioShortName.toString() || "";

  const fullLanguage = i18n?.language || "fi";
  const language = fullLanguage === "se-SE" ? "sv" : fullLanguage.split("-")[0];

  const { handleTrade: handleDeposit, submitting } = useKlarnaDeposit({
    portfolioShortName,
    amount: amount.toString(),
    language: language,
    currency,
  });

  const updateTransferStatus = async (
    response: ImportDepositQueryResponse,
    status: string
  ) => {
    const apiUrl = process.env.REACT_APP_TRANSFER_API_URL || "";
    const putData = {
      extId: response.extId,
      sessionId: response.sessionId,
      status,
    };
    const authToken = await keycloakService.getToken();

    try {
      const apiResponse = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(putData),
      });

      if (!apiResponse.ok) {
        throw new Error(`API call failed with status ${apiResponse.status}`);
      }
    } catch (error) {
      console.error("PUT API call error:", error);
    }
  };

  const startKlarnaOpenBankingXS2AApp = (
    response: ImportDepositQueryResponse
  ) => {
    const script = document.createElement("script");
    script.src =
      "https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js";
    script.async = true;
    script.onload = () => {
      const token = response.clientToken;
      try {
        window.XS2A.startFlow(token, {
          autoClose: true,
          onFinished: async () => {
            console.log("onFinished");
            await updateTransferStatus(response, "FINISHED");
            setTimeout(async () => {
              await refetch();
            }, 2000); // 2000 milliseconds = 2 seconds
          },
          onLoad: () => {
            console.log("onLoad called");
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: async (error: any) => {
            console.error(
              "onError: something bad happened during the flow.",
              error
            );
            await updateTransferStatus(response, "ERROR");
          },
          onClose: () => {
            console.log("onClose called");
          },
        });
      } catch (e) {
        console.error(e);
      }
    };
    document.body.appendChild(script);
  };

  // get the available cash adjusted for open orders
  const { data: { availableCash = 0 } = {}, refetch } = useGetBuyData(
    portfolioId.toString()
  );

  if (isMobileApp) {
    return <DepositUnavailableInApp />;
  }

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <CashAccountSelect
        {...cashAccountSelectProps}
        {...portfolioSelectProps}
        accountSelectLabel={t("moneyModal.toAccount")}
        availableCash={availableCash}
      />
      <hr className="mb-2" />
      <div className="flex flex-col gap-4 items-stretch ">
        <Input
          ref={modalInitialFocusRef}
          value={amount || ""}
          onChange={(event) => {
            setAmount(Number(event.currentTarget.value));
          }}
          label={t("moneyModal.depositAmountInputLabel", {
            currency: currency,
          })}
          type="number"
          error={
            !isAmountCorrect && !accountsLoading
              ? t("moneyModal.amountInputError")
              : undefined
          }
        />
        <Button
          disabled={amount === 0 || accountsLoading || !isAmountCorrect}
          isLoading={submitting}
          onClick={async () => {
            const response = await handleDeposit();
            if (response) {
              startKlarnaOpenBankingXS2AApp(response);
              onClose();
              // Add a delay before refetching
              setTimeout(async () => {
                await refetch();
              }, 2000); // 2000 milliseconds = 2 seconds
            }
          }}
        >
          {t("moneyModal.depositButtonLabel")}
        </Button>
      </div>
      <hr className="my-1" />
      <div className="text-xs text-center text-gray-600 max-w-[375px]">
        {t("moneyModal.depositDisclaimer")}
      </div>
    </div>
  );
};
