/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_URL, isMobileApp } from "config";
import Keycloak, {
  KeycloakError,
  KeycloakInitOptions,
  KeycloakProfile,
  KeycloakPromise,
} from "keycloak-js";
import { PUBLIC_ROUTES } from "pages/onfidoOnboarding/publicOnboarding";
import { KeycloakServiceCapacitor } from "./keycloakServiceCapacitor";
import { getLastUsedLinkedContact, setLastUsedLinkedContact } from "./pwa";

const keycloakFile =
  process.env.REACT_APP_KEYCLOAK_CONFIG_FILE ?? "keycloak.json";

console.log(`Using ${keycloakFile} as keycloak config file.`);

const getSsoRedirectUri = () => {
  const url = new URL(
    `${process.env.PUBLIC_URL}/keycloak-silent-check-sso.html`,
    window.location.origin
  );
  return url.href;
};

const keycloakInitConfig: KeycloakInitOptions = {
  onLoad: "login-required",
  silentCheckSsoRedirectUri: getSsoRedirectUri(),
  pkceMethod: "S256",
  checkLoginIframe: false,
} as const;

/**
 * Contents not documented anywhere.
 * Created based on actual data from kvarncapitaltest and FAform definitions 28.12.2023.
 */
export interface KeycloakUserInfo {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  name?: string;
  preferred_username?: string;
  sub?: string;
  userGroups?: string[];
  linked_contact_ext_id?: string; // social security number
}

export type FAKeycloakProfile = KeycloakProfile & {
  attributes?: {
    linked_contact?: string[];
  };
};

type FAKeycloakInstance = Omit<Keycloak, "profile" | "loadUserProfile"> & {
  profile?: FAKeycloakProfile;
  loadUserProfile(): KeycloakPromise<FAKeycloakProfile, void>;
  loadUserInfo(): KeycloakPromise<KeycloakUserInfo, void>;
};

type SubscribeFunctionType = (state: KeycloakServiceStateType) => void;

export interface KeycloakServiceStateType {
  initialized: boolean;
  authenticated: boolean;
  error?: boolean;
  linkedContact?: string;
  userProfile?: KeycloakProfile;
  userInfo?: KeycloakUserInfo;
}

export const keycloakServiceInitialState = {
  initialized: false,
  authenticated: false,
  linkedContact: undefined,
  userProfile: undefined,
  userInfo: undefined,
  error: undefined,
};

class KeycloakService {
  keycloak;
  state: KeycloakServiceStateType = keycloakServiceInitialState;
  subscribeFunction: SubscribeFunctionType | undefined;

  constructor(instance: FAKeycloakInstance) {
    this.keycloak = instance;

    this.init();
  }

  // These we always override. Capacitor overrides more.
  overrideKeycloakFunctions() {
    this.keycloak.onReady = this.onReady;
    this.keycloak.onAuthError = this.onError;
    this.keycloak.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
    this.keycloak.onAuthRefreshError = this.onError;
    this.keycloak.onAuthLogout = this.onAuthLogout;
    this.keycloak.onTokenExpired = this.onTokenExpired;
  }

  initOffline() {
    const lastUsedLinkedContact = getLastUsedLinkedContact();
    if (lastUsedLinkedContact) {
      this.state = {
        ...this.state,
        initialized: true,
        authenticated: true,
        linkedContact: lastUsedLinkedContact,
      };
    } else {
      this.state = {
        ...this.state,
        error: true,
      };
    }
    this.updateState();
    const initWhenReconnect = () => {
      this.init();
      window.removeEventListener("online", initWhenReconnect);
    };
    window.addEventListener("online", initWhenReconnect);
  }

  init() {
    /**
     * Because this is implemented as a class that is initialized on export, we need to disable this behavior
     * for routes where we can't load keycloak. Otherwise simple import will cause keycloak to initialize, cause redirects etc.
     */
    if (PUBLIC_ROUTES.includes(window.location.pathname)) {
      return;
    }

    if (!window.navigator.onLine) {
      this.initOffline();
      return;
    }

    this.keycloak.init(keycloakInitConfig).catch((error) => {
      console.error(error);
      this.initOffline();
    });

    this.overrideKeycloakFunctions();
  }

  subscribe(subscribeFunction: SubscribeFunctionType) {
    this.subscribeFunction = subscribeFunction;
  }

  unsubscribe() {
    this.subscribeFunction = undefined;
  }

  notifyStateChanged() {
    this.subscribeFunction?.(this.state);
  }

  onError = (errorData?: KeycloakError) => {
    console.error(errorData);
  };

  onTokenExpired = async () => {
    await this.keycloak.updateToken(5);
  };

  onAuthRefreshSuccess = async () => {
    this.updateState();
  };

  onAuthLogout = async () => {
    this.state = keycloakServiceInitialState;
    this.updateState();
    await this.keycloak.logout();
  };

  onReady = async (authenticated: boolean) => {
    if (!authenticated) {
      this.keycloak.login();
    } else {
      try {
        const userHasRequiredRole = await this.validateRequiredRole();
        if (!userHasRequiredRole)
          throw new Error(
            "User does not have the required role. Revoking access."
          );

        this.state = {
          ...this.state,
          initialized: true,
          authenticated: authenticated,
          error: false,
        };

        await this.updateLinkedContact();

        this.updateState();
      } catch (error) {
        console.error(error);
        await this.onAuthLogout(); //logout
      }
    }
  };

  async updateLinkedContact() {
    if (this.state.authenticated) {
      const [userProfile, linkedContact, userInfo] = await Promise.all([
        this.keycloak.loadUserProfile(),
        this.getContactIdFromQuery(),
        this.keycloak.loadUserInfo(),
      ]);

      setLastUsedLinkedContact(linkedContact);
      this.state = {
        ...this.state,
        linkedContact,
        userProfile,
        userInfo,
      };
    }
  }

  updateState() {
    this.notifyStateChanged();
  }

  /**
   * Gets the /keycloak.json.
   * @returns parsed keycloak.json.
   */
  async getConfigFile() {
    try {
      const config = await fetch(`${process.env.PUBLIC_URL}/${keycloakFile}`);
      const parsedConfig = await config?.json();
      return parsedConfig;
    } catch (error) {
      console.error(`Failed to get ${keycloakFile}.`);
    }
  }

  async getToken() {
    await this.keycloak.updateToken(5);
    return this.keycloak.token;
  }

  async getContactIdFromQuery() {
    try {
      await this.keycloak.loadUserProfile();

      const response = await fetch(`${API_URL}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.getToken()}`,
        },
        mode: "cors",
        body: JSON.stringify({
          query: `
            query GetContactId{
              contact{
                id
              }
            }
          `,
        }),
      });

      if (response.status === 403) {
        console.error(`User is not authenticated.`);
        return undefined;
      }

      const parsedResponse = await response.json();
      return parsedResponse?.data?.contact?.id;
    } catch {
      console.error(`Error getting contact id.`);
    }
  }

  /**
   * Checks whether the user has a required-role
   * (if one has been specified).
   * @returns true if user has the required role
   * or if a required role was not configured.
   */
  async validateRequiredRole() {
    const keycloakJson = await this.getConfigFile();
    //optional field
    const configuredRequiredRole = keycloakJson?.["required-role"];
    //required field
    const configuredClient = keycloakJson?.["resource"];

    //no configured required role -> valid
    if (!configuredRequiredRole) return true;

    return this.keycloak.hasResourceRole(
      configuredRequiredRole,
      configuredClient
    );
  }
}

export const keycloakService = isMobileApp
  ? new KeycloakServiceCapacitor()
  : new KeycloakService(
      new Keycloak(
        `${process.env.PUBLIC_URL}/${keycloakFile}`
      ) as FAKeycloakInstance
    );
