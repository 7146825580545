import { Button } from "components/Button/Button";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_PROCESS_KEYS } from "views/onboarding/helpers";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";

export const NoPortfolios = () => {
  const navigate = useNavigate();
  const { t } = useModifiedTranslation();
  return (
    <>
      <ErrorMessage header={t("messages.noPortfolios")}>
        {t("messages.noPortfoliosInfo")}
        <div className="flex flex-col gap-2 justify-center mt-8">
          {" "}
          <Button
            onClick={() =>
              navigate(`onboarding/${ONBOARDING_PROCESS_KEYS.PRIVATE}`)
            }
          >
            {t("noPortfolio.createPrivatePortfolio")}
          </Button>
          <Button
            onClick={() =>
              navigate(`onboarding/${ONBOARDING_PROCESS_KEYS.BUSINESS}`)
            }
          >
            {t("noPortfolio.createBusinessPortfolio")}
          </Button>
        </div>
      </ErrorMessage>
    </>
  );
};
