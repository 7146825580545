/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { LoadingIndicator } from "components";
import { Formio } from "formiojs";
import {
  addErrorListItems,
  removeListItemElementById,
  replaceSubmitButton,
} from "../helpers";
import {
  InitialFormData,
  FormChangeEvent,
  FormDefinition,
  FormError,
} from "../types";

interface OnboardingFormProps {
  initialFormData: InitialFormData; // ie user info from keycloak
  formOptions: Object; // options, ie language and translation, stored and determined locally
  formDefinition: FormDefinition; // definition from FA backend
  handleSubmit: (data: any) => Promise<void>;
  isSubmitting?: boolean; // Add this prop
}

const validateAndSubmit = async (
  formRef: MutableRefObject<any>,
  errorDisplayRef: RefObject<HTMLDivElement>,
  handleSubmit: (data: any) => Promise<void>,
  setIsSubmitting: (isSubmitting: boolean) => void
) => {
  if (!formRef.current) return;
  const isValid = await formRef.current.checkValidity(null, true);
  if (isValid === true) {
    setIsSubmitting(true);
    try {
      await handleSubmit(formRef.current.submission.data);
    } finally {
      setIsSubmitting(false);
    }
  } else {
    try {
      // We call an empty submit to trigger Formio built in error display functionality.
      // Not actually submitting the form. This triggers display of error states inside the form.
      // In the catch we manually add the errors to the custom error display. This exists to allow
      // user to see and scroll to errors easily.
      await formRef.current.submit();
    } catch (errors) {
      addErrorListItems(errorDisplayRef, errors as FormError[]);
    }
  }
};

/**
 * The custom change handler is used to remove existing errors when a component is changed.
 */
const handleChange = (
  event: FormChangeEvent,
  errorDisplayRef: RefObject<HTMLElement>
) => {
  if (event?.changed?.component?.type === "hidden") return; // We don't validate hidden component in the frontend
  // only continue if component is valid
  if (
    event?.changed?.instance?.checkValidity &&
    event?.changed?.instance?.checkValidity() !== true
  ) {
    return;
  }
  const idToRemove = event?.changed?.component?.id;

  if (!errorDisplayRef.current || !idToRemove) return;

  removeListItemElementById(errorDisplayRef.current, idToRemove);
};

const useCreateForm = ({
  initialFormData,
  formOptions,
  formDefinition,
  handleSubmit,
  isSubmitting,
  setIsSubmitting,
}: OnboardingFormProps & {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}) => {
  const formRef = useRef<any>(null);
  const errorDisplayRef = useRef<any>(null);

  useEffect(() => {
    const createForm = async () => {
      formDefinition.startData = initialFormData;

      formRef.current = await Formio.createForm(
        document.getElementById("formio") as HTMLElement,
        formDefinition,
        formOptions
      );

      if (!formRef.current) return;
      formRef.current.on("change", (event: any) => {
        handleChange(event, errorDisplayRef);
      });

      // We force a redraw to ensure that the initial data is displayed in fields
      formRef.current.redraw();
      replaceSubmitButton(formRef, async () => {
        if (!isSubmitting) {
          await validateAndSubmit(
            formRef,
            errorDisplayRef,
            handleSubmit,
            setIsSubmitting
          );
        }
      });

      formRef.current.on("submit", handleSubmit);
    };

    createForm();

    // Cleanup function is necessary since we attach the form object
    // with async calls to the ref
    return () => {
      if (formRef.current) {
        formRef.current.destroy();
      }
    };
  }, [
    formDefinition,
    formOptions,
    initialFormData,
    handleSubmit,

    isSubmitting,
    setIsSubmitting,
  ]);

  return { formRef, errorDisplayRef };
};

export const KycForm = ({
  initialFormData,
  formOptions,
  formDefinition,
  handleSubmit,
  isSubmitting = false, // Add default value
}: OnboardingFormProps) => {
  const [isSubmittingState, setIsSubmittingState] = useState(isSubmitting);
  const { errorDisplayRef } = useCreateForm({
    formDefinition,
    formOptions,
    initialFormData,
    handleSubmit,
    isSubmitting: isSubmittingState,
    setIsSubmitting: setIsSubmittingState,
  });

  return (
    <>
      <div
        id="formio"
        className={isSubmittingState ? "opacity-50 pointer-events-none" : ""}
      />
      {isSubmittingState && (
        <div className="flex fixed inset-0 justify-center items-center bg-black/20">
          <LoadingIndicator center />
        </div>
      )}
      <div
        id="errors-display"
        className="overflow-hidden sticky bottom-2 z-20 mx-2"
      >
        <ol
          className="empty:hidden py-2 px-4 pl-8 max-h-60 text-sm list-disc text-red-700 underline bg-red-100 rounded border border-red-300 cursor-pointer"
          ref={errorDisplayRef}
        />
      </div>
    </>
  );
};
