import { BuyApiResponse } from "api/trading/useCryptoTrade";
import { CheckMark } from "components/CheckMark/CheckMark";

interface OpenOrderMessageDisplayProps {
  t: (key: string, options?: Record<string, unknown>) => string;
  response?: BuyApiResponse & {
    securityCode?: string;
  };
  limitOrderPrice: string;
  tentativeFee: string;
  currency: string;
}

export const OpenOrderMessageDisplay = ({
  t,
  response,
  limitOrderPrice,
  tentativeFee,
  currency,
}: OpenOrderMessageDisplayProps) => {
  return (
    <div className="flex flex-col mx-auto mb-6 text-black">
      <CheckMark center />
      <div className="mt-4 mb-4 text-xl font-semibold text-center">
        {t("tradingModal.openOrderExecuted")}
      </div>
      {response && (
        <table className="table-auto">
          <tbody>
            <tr>
              <td className="py-2 px-4 border">{t("tradingModal.side")}</td>
              <td className="py-2 px-4 uppercase border">
                {t("tradingModal.typeSell")}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.securityName")}
              </td>
              <td className="py-2 px-4 border">{response.securityCode}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">{t("tradingModal.units")}</td>
              <td className="py-2 px-4 border">{response.units}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.limitPrice")}
              </td>
              <td className="py-2 px-4 border">{limitOrderPrice}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.sellingPrice")}
              </td>
              <td className="py-2 px-4 border">
                {(Number(limitOrderPrice) * response.units).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.feePercentage")}
              </td>
              <td className="py-2 px-4 border">
                {(response.feePercentage * 100).toFixed(1)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">
                - {t("tradingModal.feeAmount")} {currency}
              </td>
              <td className="py-2 px-4 border">{tentativeFee}</td>
            </tr>
            {/* Calculate total by multiplying units * unitPrice + fee */}
            <tr>
              <td className="py-2 px-4 border">
                {t("tradingModal.total")} {currency}
              </td>
              <td className="py-2 px-4 border">
                {(
                  Number(limitOrderPrice) * response.units -
                  Number(tentativeFee)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
