import { Capacitor } from "@capacitor/core";

const { NODE_ENV, REACT_APP_DEVELOPMENT_MODE } = process.env;

export const isDevelopment =
  NODE_ENV === "development" || !!REACT_APP_DEVELOPMENT_MODE;

export const API_URL =
  process.env.REACT_APP_API_URL ?? "https://kvarncapitaltest.fasolutions.com";

export const isMobileApp = Capacitor.isNativePlatform();
