/**
 * This is a rewrite of the "keycloak-js" keycloak.decodeToken which
 * is a private method and uses deprecated APIs.
 * @param str Encoded token
 * @returns Decoded token
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function decodeToken(str: string): any {
  str = str.split(".")[1];

  str = str.replace(/-/g, "+").replace(/_/g, "/");

  // pad the string for decoding
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += "==";
      break;
    case 3:
      str += "=";
      break;
    default:
      throw new Error("Invalid token");
  }

  str = decodeBase64(str);

  return JSON.parse(str);
}

function decodeBase64(base64: string) {
  const binaryString = window.atob(base64);
  const charCodes = Array.from(binaryString).map((char) => char.charCodeAt(0));
  const byteNumbers = new Uint8Array(charCodes);
  const decoded = new TextDecoder().decode(byteNumbers);
  return decodeURIComponent(encodeURIComponent(decoded));
}
