/* eslint-disable @typescript-eslint/no-explicit-any */
import { CapacitorHttp, HttpOptions } from "@capacitor/core";
import { SecureStoragePlugin as Storage } from "capacitor-secure-storage-plugin";
import { API_URL } from "config";
import {
  KeycloakRoles,
  KeycloakResourceAccess,
  KeycloakTokenParsed,
} from "keycloak-js";
import getPkce from "oauth-pkce";
import { decodeToken } from "utils/auth";
import { bumpMobileAppLoginCount } from "utils/mobile";
import { v4 as uuid } from "uuid";
import {
  FAKeycloakProfile,
  KeycloakServiceStateType,
  KeycloakUserInfo,
  keycloakServiceInitialState,
} from "./keycloakService";

const keycloakFile =
  process.env.REACT_APP_KEYCLOAK_CONFIG_FILE ?? "keycloak.json";
const authStorageKey =
  process.env.REACT_APP_AUTH_STORAGE_KEY ?? "keycloak-auth";
const redirectUri =
  process.env.REACT_APP_KEYCLOAK_REDIRECT_URI ?? "https://app.kvarnx.com";
// We only grab the code and close browser so this work for both dev and prod
// as long as it's an allowed redirect uri in keycloak server config

// Given our limited usecase we can just hardcode these for now
const KEYCLOAK_OPTIONS = {
  redirectUri: redirectUri,
  responseMode: "fragment",
  responseType: "code",
  flow: "standard",
};

const KEYCLOAK_PATHS = {
  token: "/protocol/openid-connect/token",
  auth: "/protocol/openid-connect/auth",
  userInfo: "/protocol/openid-connect/userinfo",
};

interface KeycloakConfig {
  // loaded from file
  realm: string;
  url: string;
  clientId: string;
  requiredRole?: string;
  // derived from the above and constants
  realmUrl: string;
  tokenUrl: string;
  authUrl: string;
  userInfoUrl: string;
}

interface CallbackState {
  state: string;
  nonce: string;
  codeVerifier: string;
}

interface FullAuth extends CallbackState {
  subject: string;
  realmAccess: KeycloakRoles;
  resourceAccess: KeycloakResourceAccess;
  token: string;
  tokenParsed: KeycloakTokenParsed;
  refreshToken: string;
  refreshTokenParsed: KeycloakTokenParsed;
  idToken: string;
  idTokenParsed: KeycloakTokenParsed;
  timeSkew: number; // time dif between client and server
  userProfile?: FAKeycloakProfile;
  userInfo?: KeycloakUserInfo;
  linkedContact?: string;
}

export class KeycloakServiceCapacitor {
  state: KeycloakServiceStateType = keycloakServiceInitialState; // external state watched in the provider
  subscribeFunction: ((state: KeycloakServiceStateType) => void) | undefined; // function to call when state changes
  config: KeycloakConfig | null = null;
  callbackState: CallbackState | null = null;
  fullAuth: FullAuth | null = null;
  authRefreshTimeout: NodeJS.Timeout | null = null;

  constructor() {
    this.init();
  }

  loadConfig = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/${keycloakFile}`);

      const keycloakFromFileConfig = (await response?.json()) as {
        realm: string;
        resource: string;
        "auth-server-url": string;
        "required-role": string | undefined;
      };

      const realmUrl = `${keycloakFromFileConfig["auth-server-url"]}/realms/${keycloakFromFileConfig.realm}`;
      const tokenUrl = `${realmUrl}${KEYCLOAK_PATHS.token}`;
      const authUrl = `${realmUrl}${KEYCLOAK_PATHS.auth}`;
      const userInfoUrl = `${realmUrl}${KEYCLOAK_PATHS.userInfo}`;
      const parsedConfig: KeycloakConfig = {
        realm: keycloakFromFileConfig.realm,
        url: keycloakFromFileConfig["auth-server-url"],
        clientId: keycloakFromFileConfig.resource,
        realmUrl,
        tokenUrl,
        authUrl,
        userInfoUrl,
        requiredRole: keycloakFromFileConfig?.["required-role"],
      };

      this.config = parsedConfig;
    } catch (error) {
      console.error("Failed to load config.");
    }
  };

  subscribe(subscribeFunction: (state: KeycloakServiceStateType) => void) {
    this.subscribeFunction = subscribeFunction;
  }

  unsubscribe() {
    this.subscribeFunction = undefined;
  }

  triggerStateUpdate = () => {
    this.subscribeFunction && this.subscribeFunction(this.state);
  };

  init = async () => {
    await this.loadConfig();

    // check for stored auth first
    const loggedInWithExisting = await this.initWithExistingAuth();
    if (loggedInWithExisting) {
      return;
    }

    const loggedInWithLogin = await this.initWithLogin(() => this.onInitDone());

    if (!loggedInWithLogin) {
      this.state = {
        ...this.state,
        initialized: true,
        authenticated: false,
        error: true,
      };
      this.triggerStateUpdate();
    }
  };

  onInitDone = async (): Promise<void> => {
    const userHasRequiredRole = this.checkRequiredRole();
    if (!userHasRequiredRole) {
      await this.resetAuth();
    }
    await this.syncUser();
    this.triggerStateUpdate();
    await this.storeAuth();
  };

  // Initialize from stored auth. Returns false if no stored auth or it's expired.
  initWithExistingAuth = async (): Promise<boolean> => {
    try {
      const storedAuth = await this.getAuthFromStorage();
      if (!storedAuth) {
        return false;
      }

      const authIsExpired = this.isRefreshTokenExpired(
        storedAuth.refreshTokenParsed
      );

      if (authIsExpired) {
        await this.clearStoredAuth();
        return false;
      }

      this.fullAuth = storedAuth;
      const {
        userProfile: storedUserProfile,
        linkedContact: storedLinkedContact,
        userInfo: storedUserInfo,
      } = storedAuth;

      this.state = {
        ...this.state,
        ...(storedUserProfile ? { userProfile: storedUserProfile } : {}),
        ...(storedUserInfo ? { userInfo: storedUserInfo } : {}),
        ...(storedLinkedContact ? { linkedContact: storedLinkedContact } : {}),
        initialized: true,
        authenticated: true,
        error: false,
      };

      await this.onInitDone();
      return true;
    } catch (error) {
      console.error("Failed to initialize.");
      return false;
    }
  };

  initWithLogin = async (
    loginDoneCallback: () => Promise<void>
  ): Promise<boolean> => {
    try {
      const createUrlResult = await this.createLoginUrlAndCallbackState();
      if (!createUrlResult) {
        return false;
      }
      const { loginUrl, callbackState } = createUrlResult;

      this.callbackState = callbackState;

      const windowRef = this.cordovaOpenWindowWrapper(loginUrl);

      let completed = false;
      let closed = false;

      const closeBrowser = () => {
        closed = true;
        windowRef.close();
      };

      windowRef.addEventListener("loadstart", async (event: any) => {
        if (event.url.indexOf(redirectUri) === 0) {
          closeBrowser();
          completed = true;
          const { state, code } = this.parseCodeCallbackUrl(event.url);

          await this.processCodeCallback({
            code,
            state,
          });
          bumpMobileAppLoginCount();
          await loginDoneCallback();
        }
      });

      windowRef.addEventListener("loaderror", (event: any) => {
        if (completed) return;
        if (event.url.indexOf(redirectUri) === 0) {
          closeBrowser();
          completed = true;
        } else {
          closeBrowser();
          this.resetAuth();
        }
      });

      windowRef.addEventListener("exit", () => {
        if (!closed) {
          this.resetAuth();
        }
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  createLoginUrlAndCallbackState = async (): Promise<
    | {
        loginUrl: string;
        callbackState: CallbackState;
      }
    | false
  > => {
    if (!this.config) {
      console.error("Failed to create login url.");
      return false;
    }

    const state = uuid();
    const nonce = uuid();

    const challengePromise = (): Promise<{
      code_challenge: string;
      code_verifier: string;
    }> => {
      return new Promise((resolve, reject) => {
        // using same length as keycloak-js
        getPkce(96, (error, { verifier, challenge }) => {
          if (error) {
            console.error("Failed to create login url.");
            reject(error);
            return;
          }
          resolve({ code_verifier: verifier, code_challenge: challenge });
        });
      });
    };

    const { code_verifier, code_challenge } = await challengePromise();

    let loginUrl = `${this.config.authUrl}?`;

    loginUrl += "client_id=" + this.config.clientId;
    loginUrl += "&redirect_uri=" + encodeURIComponent(redirectUri);
    loginUrl += "&state=" + state;
    loginUrl += "&response_mode=fragment";
    loginUrl += "&response_type=code";
    loginUrl += "&scope=openid";
    loginUrl += "&nonce=" + nonce;
    loginUrl += "&code_challenge=" + code_challenge;
    loginUrl += "&code_challenge_method=S256";

    return {
      loginUrl,
      callbackState: { codeVerifier: code_verifier, nonce, state },
    };
  };

  // Get initial tokens with code from redirect
  processCodeCallback = async (callback: { code: string; state: string }) => {
    try {
      const storedCallbackState = this.callbackState;
      if (!storedCallbackState || !this.config) {
        throw new Error("Prequisites not met to process callback code.");
      }

      const { code } = callback;

      let params = "code=" + code + "&grant_type=authorization_code";
      params += "&client_id=" + this.config.clientId;
      params += "&redirect_uri=" + KEYCLOAK_OPTIONS.redirectUri;
      params += "&code_verifier=" + storedCallbackState.codeVerifier;

      const getTokenWithCodeOptions: HttpOptions = {
        url: this.config.tokenUrl,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      };

      const timeBeforeAsyncCall = Date.now();

      const { data } = await CapacitorHttp.request(getTokenWithCodeOptions);

      const timeAfterAsyncCall = Date.now();
      const localTime = (timeBeforeAsyncCall + timeAfterAsyncCall) / 2;

      const { access_token, refresh_token, id_token } = data;
      const tokenParsed = decodeToken(access_token);
      const refreshTokenParsed = decodeToken(refresh_token);
      const idTokenParsed = decodeToken(id_token);

      if (
        !storedCallbackState.nonce ||
        tokenParsed.nonce !== storedCallbackState.nonce ||
        refreshTokenParsed.nonce !== storedCallbackState.nonce ||
        idTokenParsed.nonce !== storedCallbackState.nonce
      ) {
        throw new Error("Invalid nonce.");
      }

      this.setAuth(
        {
          token: access_token,
          refreshToken: refresh_token,
          idToken: id_token,
          tokenParsed,
          refreshTokenParsed,
          idTokenParsed,
        },
        localTime
      );
      await this.storeAuth();
    } catch (error) {
      this.resetAuth();
    }
  };

  // Store auth and set state as authenticated
  setAuth = (
    tokens: Pick<
      FullAuth,
      | "token"
      | "refreshToken"
      | "idToken"
      | "tokenParsed"
      | "refreshTokenParsed"
      | "idTokenParsed"
    >,
    timeLocalMs: number
  ) => {
    if (this.authRefreshTimeout) {
      clearTimeout(this.authRefreshTimeout);
      this.authRefreshTimeout = null;
    }

    const {
      token,
      refreshToken,
      idToken,
      tokenParsed,
      refreshTokenParsed,
      idTokenParsed,
    } = tokens;

    if (
      !tokenParsed.sub ||
      !tokenParsed.realm_access ||
      !tokenParsed.resource_access ||
      !tokenParsed.iat ||
      !tokenParsed.session_state ||
      !tokenParsed.exp
    ) {
      throw new Error(`Invalid token.`);
    }

    // One of these must exist or we can't complete auth
    const callBackOrFullAuth = this.callbackState ?? this.fullAuth;

    if (!callBackOrFullAuth) {
      throw new Error("No callback state or full auth.");
    }

    const { state, nonce, codeVerifier } = callBackOrFullAuth;

    if (!state || !nonce || !codeVerifier) {
      throw new Error("No callback state.");
    }

    const fullAuth: FullAuth = {
      codeVerifier,
      nonce,
      state,
      subject: tokenParsed.sub,
      realmAccess: tokenParsed.realm_access,
      resourceAccess: tokenParsed.resource_access,
      token,
      tokenParsed,
      refreshToken,
      refreshTokenParsed,
      idToken,
      idTokenParsed,
      timeSkew: Math.floor(timeLocalMs / 1000) - tokenParsed.iat,
    };

    this.fullAuth = fullAuth;
    this.callbackState = null;

    const expiresIn =
      (tokenParsed.exp - Date.now() / 1000 + this.fullAuth.timeSkew) * 1000;

    if (expiresIn <= 0) {
      this.updateAuth(0);
    } else {
      this.authRefreshTimeout = setTimeout(() => this.updateAuth(0), expiresIn);
    }

    this.state = {
      ...this.state,
      initialized: true,
      authenticated: true,
      error: false,
    };
  };

  updateAuth = async (minValidity = 5): Promise<void> => {
    const shouldUpdateToken =
      minValidity === -1 || // Forced update called
      this.isAccessTokenExpired(minValidity); // Token has expired or is close to

    if (!shouldUpdateToken) {
      return;
    }

    if (!this.fullAuth || !this.config) {
      console.error("Can't update auth.");
      this.resetAuth();
      return;
    }

    const refreshToken = this.fullAuth.refreshToken;
    if (!refreshToken) {
      return;
    }

    let reqParams = "";
    reqParams += "grant_type=refresh_token";
    reqParams += "&client_id=" + this.config.clientId;
    reqParams += "&refresh_token=" + refreshToken;

    const updateTokenOptions: HttpOptions = {
      url: this.config.tokenUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: reqParams,
    };

    try {
      const timeBeforeAsyncCall = Date.now();

      const { data, status } = await CapacitorHttp.request(updateTokenOptions);

      if (status !== 200) {
        throw new Error(`Request to get token failed with status ${status}`);
      }

      const timeAfterAsyncCall = Date.now();
      const localTime = (timeBeforeAsyncCall + timeAfterAsyncCall) / 2;

      const { access_token, refresh_token, id_token } = data;
      const tokenParsed = decodeToken(access_token);
      const refreshTokenParsed = decodeToken(refresh_token);
      const idTokenParsed = decodeToken(id_token);

      this.setAuth(
        {
          token: access_token,
          refreshToken: refresh_token,
          idToken: id_token,
          tokenParsed,
          refreshTokenParsed,
          idTokenParsed,
        },
        localTime
      );
      this.triggerStateUpdate();
      await this.storeAuth();
    } catch (error) {
      console.error("Failed to update token.");
      this.resetAuth();
    }
  };

  resetAuth = async (): Promise<void> => {
    await this.clearStoredAuth();
    this.fullAuth = null;
    this.callbackState = null;
    this.state = keycloakServiceInitialState;
    this.triggerStateUpdate();
    this.init();
  };

  // This wrapper exists to keep the same interface as the web keycloak-js version
  onAuthLogout = async (): Promise<void> => {
    await this.resetAuth();
  };

  async getToken(): Promise<string> {
    if (!this.fullAuth) {
      return ""; // Doing a safe return here to avoid unforeseen errors
    }
    await this.updateAuth(2);
    if (!this.fullAuth?.token) {
      return ""; // Doing a safe return here to avoid unforeseen errors
    }
    return this.fullAuth.token;
  }

  getUserProfile = async (): Promise<FAKeycloakProfile | null> => {
    try {
      const reqUrl = this.config?.realmUrl + "/account";

      const loadUserProfileOptions: HttpOptions = {
        url: reqUrl,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.getToken()}`,
        },
      };
      const { status, data } = await CapacitorHttp.request(
        loadUserProfileOptions
      );

      if (status === 200) return data;
      console.error("Failed to load user profile.");
    } catch (error) {
      console.error("Failed to load user profile.");
    }
    return null;
  };

  getUserInfo = async (): Promise<KeycloakUserInfo | null> => {
    try {
      const reqUrl = this.config?.userInfoUrl;

      if (!reqUrl) {
        console.warn("No userInfo url in config.");
        return null;
      }

      const loadUserInfoOptions: HttpOptions = {
        url: reqUrl,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.getToken()}`,
        },
      };
      const { status, data } = await CapacitorHttp.request(loadUserInfoOptions);

      if (status === 200) return data;
      console.error("Failed to load user info.");
    } catch (error) {
      console.error("Failed to load user info.");
    }
    return null;
  };

  async getContactId(): Promise<string | null> {
    try {
      const reqUrl = API_URL + "/graphql";

      const getContactIdOptions: HttpOptions = {
        url: reqUrl,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.getToken()}`,
        },
        data: {
          query: `
            query GetContactId{
              contact{
                id
              }
            }
          `,
        },
      };

      const { data } = await CapacitorHttp.request(getContactIdOptions);
      const id = data?.data?.contact?.id;

      if (id) return id;
    } catch {
      console.error(`Error getting contact id.`);
    }
    return null;
  }

  syncUser = async (): Promise<void> => {
    const [userProfile, linkedContact, userInfo] = await Promise.all([
      this.getUserProfile(),
      this.getContactId(),
      this.getUserInfo(),
    ]);

    if (!this.fullAuth) return;

    if (userProfile) {
      this.state = {
        ...this.state,
        userProfile,
      };
      this.fullAuth = {
        ...this.fullAuth,
        userProfile,
      };
    }

    if (linkedContact) {
      this.state = {
        ...this.state,
        linkedContact,
      };
      this.fullAuth = {
        ...this.fullAuth,
        linkedContact,
      };
    }

    if (userInfo) {
      this.state = {
        ...this.state,
        userInfo,
      };
      this.fullAuth = {
        ...this.fullAuth,
        userInfo,
      };
    }
  };

  checkRequiredRole(): boolean {
    if (!this.config?.requiredRole) {
      return true;
    }

    const access = this.fullAuth?.resourceAccess[this.config?.clientId ?? ""];
    return !!access && access.roles.includes(this.config.requiredRole);
  }

  /**
   * STORAGE
   */

  clearStoredAuth = async (): Promise<void> => {
    await Storage.remove({ key: authStorageKey });
  };

  getAuthFromStorage = async (): Promise<FullAuth | null> => {
    try {
      const storedAuth = await Storage.get({ key: authStorageKey });
      const storedKeycloakAuth: FullAuth = JSON.parse(storedAuth.value);
      return storedKeycloakAuth;
    } catch (error) {
      // The stored auth throws if key doesn't exist
      return null;
    }
  };

  storeAuth = async (): Promise<void> => {
    if (!this.fullAuth) {
      console.error("Failed to store auth.");
      return;
    }
    await Storage.set({
      key: authStorageKey,
      value: JSON.stringify(this.fullAuth),
    });
  };

  async loadUserInfo() {
    return {};
  }

  /**
   * HELPERS
   */

  parseCodeCallbackUrl = (url: string): { state: string; code: string } => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.hash.substring(1));
    const state = params.get("state");
    const session_state = params.get("session_state");
    const code = params.get("code");
    if (!state || !session_state || !code) {
      throw new Error("Missing parameters in url.");
    }
    return { state, code };
  };

  cordovaOpenWindowWrapper = (loginUrl: string) => {
    // Use inappbrowser for IOS and Android if available
    if (window.cordova && window.cordova.InAppBrowser) {
      return window.cordova.InAppBrowser.open(
        loginUrl,
        "_blank",
        // @ref see https://cordova.apache.org/docs/en/6.x/reference/cordova-plugin-inappbrowser/ for options
        "location=no,hiddes=yes,zoom=no,clearsessioncache=yes,clearcache=yes,toolbar=no"
      );
    }

    return window.open(loginUrl, "_blank");
  };

  isAccessTokenExpired = (minValidity: number): boolean => {
    if (!this.fullAuth) {
      return true;
    }
    const expiresIn =
      (this.fullAuth.tokenParsed.exp ?? 0) -
      Math.ceil(Date.now() / 1000) +
      this.fullAuth.timeSkew;

    return expiresIn < minValidity;
  };

  isRefreshTokenExpired = (
    parsedRefreshToken: FullAuth["refreshTokenParsed"]
  ): boolean => {
    const refreshTokenExpiration = parsedRefreshToken.exp ?? 0;
    const secondsLeft = refreshTokenExpiration - Date.now() / 1000;
    const FIFTEEN_MINUTES_AS_SECONDS = 15 * 60;
    return secondsLeft < FIFTEEN_MINUTES_AS_SECONDS;
  };
}
