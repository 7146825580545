import { useState } from "react";
import {
  Process,
  useGetContactProcesses,
} from "api/flowable/useGetContactProcesses";
import { QueryLoadingWrapper } from "components";
import { Navigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  getOnboardingProcessType,
  storeOnboardingProcessType,
  useCampaignCode,
} from "views/onboarding/helpers";
import { NoOnboardingProcess } from "./components/NoOnboardingProcess";
import { SelectOnboardingType } from "./components/SelectOnboardingType";

export const OnboardingNeededWrapper = () => {
  const queryData = useGetContactProcesses("ONBOARDING");
  return (
    <QueryLoadingWrapper {...queryData} SuccessComponent={OnboardingNeeded} />
  );
};

interface MissingLinkedContactProps {
  data: Process[];
}

const OnboardingNeeded = ({
  data: onboardingProcesses,
}: MissingLinkedContactProps) => {
  useCampaignCode();
  const { formKey } = useParams();
  const constProcessType = formKey || getOnboardingProcessType() || "";
  const [onboardingProcessType, setOnboardingProcessType] =
    useState(constProcessType);

  const selectOnboardingProcessType = (key: string) => {
    storeOnboardingProcessType(key);
    setOnboardingProcessType(key);
  };

  if (!onboardingProcessType) {
    return (
      <SelectOnboardingType
        selectOnboardingProcessType={selectOnboardingProcessType}
      />
    );
  }

  const onboardingProcess = onboardingProcesses.find(
    (process) => process.key === onboardingProcessType
  );

  if (onboardingProcess) {
    const { key } = onboardingProcess;
    return <Navigate to={`/onboarding/${key}`} />;
  }

  return <NoOnboardingProcess />;
};
