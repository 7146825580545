import React, { useState, useEffect } from "react";
import iphoneImage from "../../../src/assets/images/kvarnx-phone.png";
import logo from "../../../src/assets/images/logo_onboarding.png";
import { useGetRedirectUrl } from "../../api/onfido/useGetRedirectUrl";

export const PUBLIC_ROUTES = [
  "/public-onboarding",
  "/onboarding/publicOnboarding",
  "/signicat",
  "/public-wait-portfolio-activation",
];

const PublicPage: React.FC = () => {
  const { getRedirectUrl, submitting } = useGetRedirectUrl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Check if we have verification data in localStorage
    const dossierId = localStorage.getItem("signicatDossierId");
    const processId = localStorage.getItem("signicatProcessId");
    const status = localStorage.getItem("signicatStatus");

    if (dossierId && processId && status) {
      // Clear the localStorage after using the data
      localStorage.removeItem("signicatDossierId");
      localStorage.removeItem("signicatProcessId");
      localStorage.removeItem("signicatStatus");
    }
  }, []);

  const handleVerificationFlow = async () => {
    try {
      const result = await getRedirectUrl();
      if (result.url) {
        window.location.href = result.url;
      } else {
        setError("No redirect URL received");
      }
    } catch (error) {
      console.error("Error starting verification flow:", error);
      setError("Failed to start verification flow");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container py-6 px-4 mx-auto max-w-6xl">
        <img src={logo} alt="Kvarn X Logo" className="w-44 h-auto" />
      </div>
      <div className="flex flex-grow items-center">
        <div className="container flex flex-col md:flex-row items-center px-4 mx-auto max-w-6xl">
          <div className="mb-8 md:mb-0 md:w-1/2">
            <h1 className="mb-8 text-4xl font-bold text-kvarn-dark-green">
              Welcome to Kvarn X
            </h1>
            <p className="mb-6 text-lg">
              You can open a Kvarn X account by authenticating with your
              passport.
            </p>
            <p className="mb-6 text-lg">
              To open an account using passport you must be 18 years of age or
              older and legally competent person.
            </p>
            <p className="mb-6 text-lg">
              Before you click Sign up, make sure you have the following
              document ready: Valid passport.
            </p>
            <p className="mb-6 text-lg">
              Ensure you have your passport at hand from one of the following
              countries: Austria, Belgium, Estonia, France, Germany, Italy,
              Latvia, Lithuania, Luxembourg, Malta, Monaco, Norway, Poland,
              Portugal, Spain.
            </p>
            <p className="mb-6 text-lg">
              Once your passport is ready, you can proceed by clicking Sign Up
              to start the onboarding process.
            </p>
            <button
              onClick={handleVerificationFlow}
              disabled={submitting}
              className={`w-full md:w-auto py-3 px-6 rounded-full ${
                submitting
                  ? "bg-kvarn-dark-green cursor-not-allowed"
                  : "bg-kvarn-light-green hover:bg-kvarn-dark-green border border-gray-400"
              } text-kvarn-dark-green hover:text-kvarn-light-green font-semibold text-lg`}
            >
              {submitting ? "Starting..." : "Sign up"}
            </button>
            {error && <p className="mt-4 text-center text-red-500">{error}</p>}
          </div>
          <div className="md:w-1/2">
            <img
              src={iphoneImage}
              alt="Kvarn X App Interface"
              className="mx-auto w-full max-w-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicPage;
