import { useState } from "react";
import { ApolloError } from "@apollo/client";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { toast } from "react-toastify";
import { keycloakService } from "../../services/keycloakService";

interface transferSchema {
  amount: string;
  currency: string;
  portfolioShortName: string;
  language: string;
}

interface ImportDepositQueryResponse {
  clientToken: string;
  extId: string;
  sessionId: string;
}

export const depositType = "deposit" as const;

export const useKlarnaDeposit = (newTransaction: transferSchema) => {
  useModifiedTranslation();
  const [submitting, setSubmitting] = useState(false);

  const handleTrade = async () => {
    setSubmitting(true);
    try {
      const { portfolioShortName } = newTransaction;
      if (!portfolioShortName) {
        return;
      }

      const token = await keycloakService.getToken();

      const apiResponse = await fetch(
        process.env.REACT_APP_TRANSFER_API_URL || "",
        {
          method: "POST",
          body: JSON.stringify(newTransaction),
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${token}`,
          },
        }
      );

      const jsonResponse =
        (await apiResponse.json()) as ImportDepositQueryResponse;

      handleBadAPIResponse(jsonResponse);

      setSubmitting(false);
      //   toast.success(t("moneyModal.depositSuccess"), { autoClose: 3000 });
      return jsonResponse;
    } catch (e: unknown) {
      const error = e as Error | ApolloError;
      toast.error(error.message, {
        style: { whiteSpace: "pre-line" },
      });
      setSubmitting(false);
      return null;
    }
  };

  return { handleTrade, submitting };
};

const handleBadAPIResponse = (jsonResponse: ImportDepositQueryResponse) => {
  if (!jsonResponse === undefined) {
    throw new Error("Empty response");
  }
};
