interface ErrorMessageDisplayProps {
  errorMessage: string;
  t: (key: string, options?: Record<string, unknown>) => string;
}

export const ErrorMessageDisplay: React.FC<ErrorMessageDisplayProps> = ({
  errorMessage,
  t,
}) => {
  return (
    <div className="flex flex-col mx-auto mb-6 text-black">
      <div className="mt-4 mb-4 text-xl font-semibold text-center">
        {t("tradingModal.orderRejected")}
      </div>
      <div className="mt-4 mb-4 max-w-xs text-center text-md">
        {errorMessage}
      </div>
    </div>
  );
};
