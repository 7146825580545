import { useState } from "react";
import { keycloakService } from "../../services/keycloakService";

export interface Contact {
  contactId: string;
  name: string;
  type: string;
  taxCountry: string;
  juridical: string;
  status: string;
  classification: string;
  identity: string;
  profileAttributes: string;
  address1: string;
  city: string;
  country: string;
  zipCode: string;
  nationality: string;
  phone1: string;
  email: string;
  memo: string;
  electronicCommunication: boolean;
  marketingCommunication: boolean;
}

export interface ImportContactsRequest {
  contactList: Contact[];
}

export interface ImportContactsResponse {
  message: string;
}

export function useUpdateUserInfo() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateUserInfo = async (
    contactList: Contact[]
  ): Promise<ImportContactsResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const token = await keycloakService.getToken();
      const response = await fetch(
        process.env.REACT_APP_KYC_UPDATE_API_URL || "",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ contactList } as ImportContactsRequest),
        }
      );

      // Check if the response status is 405
      if (response.status === 405) {
        throw new Error(
          "Method Not Allowed: Please verify the HTTP method and endpoint."
        );
      }

      // Check if the response has content
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to import contacts");
        }

        const data: ImportContactsResponse = await response.json();
        return data;
      } else {
        throw new Error("Unexpected response format.");
      }
    } catch (err: unknown) {
      console.error("Error importing contacts:", err);
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateUserInfo,
    isLoading,
    error,
  };
}
