import { useState } from "react";

interface ImportData {
  c_name: string;
  c_externalId: string;
  c_email: string;
  c_phone1: string;
  p_language: string;
  c_taxCountry: string;
  c_nationality: string;
  c_profileAttribute_contact_muuttiedot_fatcayksityishenkilo: string;
  c_profileAttribute_contact_muuttiedot_taxcountry2: string;
  c_contactId: string;
  c_address1: string;
  c_zipCode: string;
  c_city: string;
  c_country: string;
  c_profileAttribute_contact_profiling_years: string;
  c_profileAttribute_contact_profiling_horizon: string;
  c_profileAttribute_contact_profiling_risk: string;
  c_profileAttribute_contact_profiling_kokemuskryptot: string;
  c_profileAttribute_contact_profiling_kokonaisvarallisuus: string;
  c_profileAttribute_contact_profiling_averageInvestment: string;
  c_profileAttribute_contact_profiling_frequency: string;
  c_profileAttribute_contact_profiling_palkkatulo: boolean;
  c_profileAttribute_contact_profiling_yritystulo: boolean;
  c_profileAttribute_contact_profiling_saastot: boolean;
  c_profileAttribute_contact_profiling_perintolahja: boolean;
  c_profileAttribute_contact_profiling_sijoitustuotot: boolean;
  c_profileAttribute_contact_profiling_asuntokiinteisto: boolean;
  c_profileAttribute_contact_profiling_yritysmyynti: boolean;
  c_profileAttribute_contact_profiling_muumyyntitulo: boolean;
  c_profileAttribute_contact_profiling_vakuutusmaksu: boolean;
  c_profileAttribute_contact_profiling_avustukset: boolean;
  c_profileAttribute_contact_profiling_stocks: boolean;
  c_profileAttribute_contact_profiling_bonds: boolean;
  c_profileAttribute_contact_profiling_funds: boolean;
  c_profileAttribute_contact_profiling_etf: boolean;
  c_profileAttribute_contact_profiling_derivatives: boolean;
  c_profileAttribute_contact_profiling_structured: boolean;
  c_profileAttribute_contact_profiling_cryptocurrencies: boolean;
  c_profileAttribute_contact_profiling_other: boolean;
  c_classification: string;
  p_currency: string;
  c_profileAttribute_contact_muuttiedot_tilaan: boolean;
  c_profileAttribute_contact_muuttiedot_kayttoehdot: boolean;
  p_profileAttribute_portfolio_coupon_code: string;
  c_profileAttribute_contact_trapets_dateofbirth: string;
  c_profileAttribute_contact_trapets_risk: string;
  p_profileAttribute_portfolio_risklevel_risk: string;
  p_type: string;
  p_profileAttribute_portfolio_feelevel_calculationmethod: string;
  p_profileAttribute_portfolio_feelevel_feeleveltag: string;
  p_profileAttribute_portfolio_feelevel_feepercentage: string;
  p_profileAttribute_portfolio_feelevel_cumulativetradeamount: string;
  p_profileAttribute_portfolio_feelevel_observationperiod: string;
  p_strategy: string;
  c_memo: string;
  p_creationDate: string;
  p_startDate: string;
  c_tags: string;
  p_tags: string;
  p_extId: string;
  p_accounts: string;
  p_primaryContact: string;
  p_shortName: string;
  c_language: string;
  p_name: string;
  p_status: string;
  p_valuationMethod: string;
  c_type: string;
  c_status: string;
  c_juridical: string;
  p_juridical: string;
  p_country: string;
  c_identity: string;
  c_profileAttribute_contact_profiling_completeDate: string;
  c_profileAttribute_contact_profiling_kokemuskryptotvuosia: string;
  c_profileAttribute_contact_companyDetails_companytaxcountry: string;
  c_profileAttribute_contact_companyDetails_valtuutus: string;
  c_profileAttribute_contact_companyDetails_companycity: string;
  c_profileAttribute_contact_companyDetails_companycountry: string;
  c_profileAttribute_contact_companyDetails_companyBusinessArea: string;
  c_profileAttribute_contact_companyDetails_companyBusinessDescreption: string;
  c_profileAttribute_contact_companyDetails_companyRevenue: string;
  c_profileAttribute_contact_companyDetails_assets: string;
  c_profileAttribute_contact_companyDetails_reasons: string;
  c_profileAttribute_contact_companyDetails_origin: string;
  c_profileAttribute_contact_companyDetails_lei: string;
  c_profileAttribute_contact_companyDetails_companyTIN: string;
  c_profileAttribute_contact_companyDetails_fatcastatus: string;
  c_profileAttribute_contact_beneficiaries_ubos: string;
  c_profileAttribute_contact_beneficiaries_hetu1: string;
  c_profileAttribute_contact_beneficiaries_nimi1: string;
  c_profileAttribute_contact_beneficiaries_osoite1: string;
  c_profileAttribute_contact_beneficiaries_postinumero1: string;
  c_profileAttribute_contact_beneficiaries_paikkakunta1: string;
  c_profileAttribute_contact_beneficiaries_ownership1: string;
  c_profileAttribute_contact_beneficiaries_verotusmaa1: string;
  c_profileAttribute_contact_beneficiaries_kansalaisuus1: string;
  c_profileAttribute_contact_beneficiaries_sahkoposti1: string;
  c_profileAttribute_contact_beneficiaries_puhelinnumero1: string;
  c_profileAttribute_contact_beneficiaries_pep1: string;
}

interface StartProcessPayload {
  key: string;
  data: {
    import: ImportData;
    tags: {
      pep: string;
    };
    account_number: string;
    account_bic: string;
    account_curr: string;
    submit: boolean;
    tags_pep: string;
  };
}

interface StartProcessResponse {
  ok?: boolean;
  error?: string;
}

export const useStartPublicProcess = (key: string | undefined) => {
  const [data, setData] = useState<StartProcessResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const startProcess = async (payload: Omit<StartProcessPayload, "key">) => {
    if (!key) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        process.env.REACT_APP_START_PUBLIC_PROCESS_API_URL || "",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ key, ...payload }),
        }
      );

      const result: StartProcessResponse = await response.json();

      if (!result.ok) {
        throw new Error(result.error || "Failed to start process");
      }

      setData(result);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("An error occurred"));
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setData(null);
    setError(null);
  };

  return {
    startProcess,
    data,
    loading,
    error,
    reset,
  };
};
