import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateVerificationFlow } from "../../api/onfido/useUpdateVerificationFlow";

const SignicatRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateVerificationFlow, submitting, error } =
    useUpdateVerificationFlow();

  useEffect(() => {
    const handleRedirect = async () => {
      const searchParams = new URLSearchParams(location.search);
      const dossierId = searchParams.get("dossierId");
      const processId = searchParams.get("processId");
      const status = searchParams.get("status");
      const finalResult = searchParams.get("finalResult");

      if (dossierId && processId && status) {
        // 1. Save parameters to localStorage
        localStorage.setItem("signicatDossierId", dossierId);
        localStorage.setItem("signicatProcessId", processId);
        localStorage.setItem("signicatStatus", status);

        // Save finalResult object to localStorage
        if (finalResult) {
          try {
            const parsedFinalResult = JSON.parse(finalResult);
            localStorage.setItem(
              "signicatFinalResult",
              JSON.stringify(parsedFinalResult)
            );
          } catch (error) {
            console.error("Failed to parse finalResult:", error);
          }
        }

        try {
          // 2. Call useUpdateVerificationFlow hook
          console.log("Updating verification flow");
          await updateVerificationFlow(dossierId, processId);

          // 3. Redirect to onboarding form
          navigate("/onboarding/publicOnboarding");
        } catch (err) {
          console.error("Failed to update verification flow:", err);
          // Handle error - you might want to redirect to an error page or show an error message
          navigate("/onboarding/error");
        }
      } else {
        console.error("Missing required query parameters");
        navigate("/onboarding/error");
      }
    };

    handleRedirect();
  }, [location.search, updateVerificationFlow, navigate]);

  if (submitting) {
    return <div>Processing verification...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Redirecting...</div>;
};

export default SignicatRedirect;
