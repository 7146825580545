import React from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

// Maintenance  is on Sundays 10:00-11:30 Finnish time during daylight saving time
// and 11:00-12:30 Finnish time during winter time.
const maintenanceIsOngoing = () => {
  // const now = new Date();
  // const dayOfWeek = now.getUTCDay();

  // Only check on Sundays
  // if (dayOfWeek !== 0) return false;

  // const hoursUTC = now.getUTCHours();
  // const minutesUTC = now.getUTCMinutes();

  // return hoursUTC === 8 || (hoursUTC === 9 && minutesUTC <= 30);
  return false;
};

/**
 * B2C2 has a weekly maintenance so we we used to show a maintenance page.
 * Now that we have other providers we don't need to show a maintenance page on a regular basis.
 */
export const MaintenanceWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { t } = useModifiedTranslation();
  const isMaintenance = maintenanceIsOngoing();

  if (!isMaintenance) return <>{children}</>;

  return (
    <div className="relative">
      {children}
      {isMaintenance && (
        <div className="flex absolute top-0 right-0 bottom-0 left-0 z-50 justify-center items-center bg-black bg-opacity-50">
          <div className="text-center">
            <img
              src={"/logo_maksable_x.png"}
              className="mx-auto mb-4 w-28 h-28"
              alt="logo"
            />
            <div className="inline-block p-8 bg-white rounded-lg border">
              <h1 className="mb-4 text-4xl font-bold">
                {t("maintenancePage.title")}
              </h1>
              <p className="text-2xl">{t("maintenancePage.info")}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
