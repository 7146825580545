import { gql, useQuery } from "@apollo/client";

const STAKING_DETAILS_QUERY = gql`
  query GetPortfolioHoldingDetails($portfolioId: Long) {
    portfolio(id: $portfolioId) {
      id
      tradeOrders(typeCodes: ["STK"], orderStatus: "5") {
        tradeAmount
        amount
      }
    }
  }
`;

const HOLDING_DETAILS_QUERY = gql`
  query GetPortfolioHoldingDetails($portfolioId: Long) {
    portfolio(id: $portfolioId) {
      id
      tradeOrders(typeCodes: ["B"], orderStatus: "5") {
        amount
        securityCode
      }
    }
  }
`;

// FA broke this query
// const HOLDING_DETAILS_QUERY_ETH = gql`
//   query GetPortfolioHoldingDetails($portfolioId: Long) {
//     portfolio(id: $portfolioId) {
//       id
//       tradeOrders(typeCodes: ["B"], orderStatus: "5", securityCode: "eth") {
//         amount
//       }
//     }
//   }
// `;

type StakingDetailsData = {
  portfolio: {
    id: string;
    tradeOrders: Array<{
      tradeAmount: number;
      amount: number;
    }>;
  };
};

type OpenLimitOrderBuyingDetailsData = {
  portfolio: {
    id: string;
    tradeOrders: Array<{
      amount: number;
      securityCode: string;
    }>;
  };
};

export const useGetStakingHoldingDetails = (
  portfolioId: string | undefined,
  securityCode: string | undefined
) => {
  const { loading, error, data, refetch } = useQuery<StakingDetailsData>(
    STAKING_DETAILS_QUERY,
    {
      variables: {
        portfolioId,
      },
      fetchPolicy: "network-only",
    }
  );

  const { data: dataHolding } = useQuery<OpenLimitOrderBuyingDetailsData>(
    HOLDING_DETAILS_QUERY,
    {
      variables: {
        portfolioId,
      },
      fetchPolicy: "network-only",
    }
  );

  // Calculate the total trade amount
  const totalStakeAmount =
    data?.portfolio?.tradeOrders.reduce(
      (acc, order) => acc + order.amount,
      0
    ) || 0;

  // Filter orders by securityCode before calculating total
  const filteredBuyOrders =
    dataHolding?.portfolio?.tradeOrders.filter(
      (order) =>
        order.securityCode.toLowerCase() === securityCode?.toLowerCase()
    ) ?? [];

  const totalLimitOrderOpenBuyingAmount = filteredBuyOrders.reduce(
    (acc, order) => acc + order.amount,
    0
  );

  return {
    loading,
    error,
    data,
    refetch,
    totalStakeAmount,
    totalLimitOrderOpenBuyingAmount,
  };
};
