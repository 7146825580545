import { gql, useQuery } from "@apollo/client";
import { PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS } from "./fragments";
import { AllPortfolioHoldingDetailsQuery, HoldingPosition } from "./types";

const HOLDINGS_DETAILS_QUERY = gql`
  ${PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS}
  query GetPortfolioHoldingDetails($portfolioId: Long) {
    portfolio(id: $portfolioId) {
      id
      portfolioReport(
        adjustPositionsBasedOnOpenTradeOrders: false
        calculateExpectedAmountBasedOpenTradeOrders: false
        excludeTradeOrderStatusOpen: true
      ) {
        portfolioId
        ...PortfolioReportHoldingDetailsFields
      }
    }
  }
`;

/**
 * Get holdings details for a single portfolio
 * @param portfolioId
 * @returns
 */
export const useGetAllPortfolioHoldingDetails = (
  portfolioId: string | undefined
) => {
  const { loading, error, data, refetch } =
    useQuery<AllPortfolioHoldingDetailsQuery>(HOLDINGS_DETAILS_QUERY, {
      variables: {
        portfolioId,
      },
      fetchPolicy: "network-only",
    });

  return {
    loading,
    error,
    data: data?.portfolio?.portfolioReport?.holdingPositions,
    refetch,
  };
};

const MULTIPLE_PORTFOLIOS_HOLDINGS_DETAILS_QUERY = gql`
  ${PORTFOLIO_REPORT_HOLDINGS_DETAILS_FIELDS}
  query GetMultiplePortfolioHoldingDetails($portfolioIds: [String]) {
    portfolios(ids: $portfolioIds) {
      id
      portfolioReport(
        adjustPositionsBasedOnOpenTradeOrders: true
        calculateExpectedAmountBasedOpenTradeOrders: true
        excludeTradeOrderStatusOpen: true
      ) {
        portfolioId
        ...PortfolioReportHoldingDetailsFields
      }
    }
  }
`;

/**
 * Get holdings details for multiple portfolios at once
 * @param portfolioIds
 * @returns
 */
export const usePortfoliosHoldingsDetails = (
  portfolioIds: string[] | undefined
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { loading, error, data, refetch } = useQuery<{
    portfolios: { portfolioReport: { holdingPositions: HoldingPosition[] } }[];
  }>(MULTIPLE_PORTFOLIOS_HOLDINGS_DETAILS_QUERY, {
    variables: {
      portfolioIds,
    },
    fetchPolicy: "network-only",
  });

  return {
    loading,
    error,
    data: data?.portfolios.map((p) => p.portfolioReport?.holdingPositions),
    refetch,
  };
};
