import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Capacitor } from "@capacitor/core";
import { API_URL } from "config";
import { capacitorHttpAsFetch } from "./capacitorHttpWrapper";
import { keycloakService } from "../keycloakService";

const baseHttpOptions = {
  uri: `${API_URL}/graphql`,
};

const mainLink = new HttpLink({
  ...baseHttpOptions,
  fetchOptions: {
    mode: "cors",
  },
});

const capacitorLink = new HttpLink({
  ...baseHttpOptions,
  fetch: capacitorHttpAsFetch,
});

const authMiddleware = setContext(async (operation, { headers }) => {
  const token = await keycloakService.getToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Portfolio: {
      fields: {
        portfolioReport: {
          merge: (existing, incoming) => {
            return { ...existing, ...incoming };
          },
        },
        portfolioGroups: {
          merge: (existing, incoming) => {
            return incoming;
          },
        },
      },
    },
    Contact: {
      fields: {
        portfolioReport: {
          merge: (existing, incoming) => {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    PortfolioReportItem: {
      keyFields: ["portfolioId", "security", ["id"]],
    },
    Document: {
      keyFields: ["identifier"],
    },
    Report: {
      keyFields: ["transactionId"],
    },
    TransactionType: {
      keyFields: ["typeCode"],
    },
  },
});

export const getPersistedApolloClient = async () => {
  if (Capacitor.getPlatform() === "ios") {
    return new ApolloClient({
      link: from([authMiddleware, mainLink]),
      cache,
    });
  }

  if (Capacitor.getPlatform() === "android") {
    return new ApolloClient({
      link: from([authMiddleware, capacitorLink]),
      cache,
    });
  }

  return new ApolloClient({
    link: from([authMiddleware, mainLink]),
    cache,
  });
};
