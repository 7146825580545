import { LogOutButton } from "components/LogOutButton/LogOutButton";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { SUPPORTED_LANGUAGES } from "utils/translations";

export const OnboardingHeader = ({
  hideLangSelection,
}: {
  hideLangSelection?: boolean;
}) => {
  const { i18n } = useModifiedTranslation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className="">
      <div className="flex items-center border-b-2">
        {" "}
        <img
          src="/logo_onboarding.png"
          alt="Kvarn Capital logo"
          className="py-2 px-4 h-14"
        />
        <div className="flex flex-grow justify-center">
          {" "}
          {!hideLangSelection &&
            SUPPORTED_LANGUAGES.map((lang) => {
              return (
                <button
                  key={lang}
                  onClick={() => handleLanguageChange(lang)}
                  className="py-1 px-2 text-kvarn-black"
                >
                  {(lang.split("-").at(0) ?? "").toUpperCase()}
                </button>
              );
            })}
        </div>
        <LogOutButton />
      </div>
    </header>
  );
};
