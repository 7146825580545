import { Switch } from "@headlessui/react";

type ToggleSwitchProps = {
  enabled: boolean;
  setEnabled: (value: boolean) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  enabled,
  setEnabled,
}) => {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? "bg-kvarn-dark-green" : "bg-gray-200",
        "relative inline-flex h-6 w-11 items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:bg-kvarn-dark-green focus:ring-offset-2"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
};

export default ToggleSwitch;
