import { useState } from "react";

interface FinalResult {
  firstName: string;
  lastName: string;
  dateOfExpiry: string;
  gender: string;
  nationality: string;
  documentType: string;
  documentNumber: string;
  issuingCountry: string;
  dateOfBirth: string;
  similarityScore: string;
}

interface ProviderSpecific {
  facialSimilarity: {
    result: string;
    imageIntegrity: string;
    faceComparison: string;
    visualAuthenticity: string;
    similarityScore: string;
  };
  documentVerification: {
    [key: string]: string;
  };
}

interface UpdateVerificationFlowResponse {
  provider: string;
  status: string;
  processId: string;
  createdAt: string;
  updatedAt: string;
  finalResult: FinalResult;
  providerSpecific: ProviderSpecific;
}

export const useUpdateVerificationFlow = () => {
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] =
    useState<UpdateVerificationFlowResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const updateVerificationFlow = async (
    dossierId: string,
    processId: string
  ): Promise<UpdateVerificationFlowResponse | null> => {
    try {
      setSubmitting(true);
      setError(null);

      const url = `${process.env.REACT_APP_VERIFICATION_FLOWS_API_URL}/${dossierId}`;

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ processId }),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.message || "Failed to update verification flow"
        );
      }

      const result: UpdateVerificationFlowResponse = await res.json();
      setResponse(result);
      return result;
    } catch (err: unknown) {
      console.error("Error updating verification flow:", err);
      setError(
        err instanceof Error ? err.message : "An unexpected error occurred"
      );
      return null;
    } finally {
      setSubmitting(false);
    }
  };

  return { updateVerificationFlow, submitting, response, error };
};
