/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { PushNotifications } from "@capacitor/push-notifications";
import { isDevelopment, isMobileApp } from "config";
import { toast } from "react-toastify";

const openCordovaWindow = (loginUrl: string) => {
  // Use inappbrowser for IOS and Android if available
  if (window.cordova && window.cordova.InAppBrowser) {
    return window.cordova.InAppBrowser.open(
      loginUrl,
      "_blank",
      // @ref see https://cordova.apache.org/docs/en/6.x/reference/cordova-plugin-inappbrowser/ for options
      "location=no,hiddes=yes,zoom=no,clearsessioncache=yes,clearcache=yes,toolbar=no"
    );
  }

  console.error("cordova.InAppBrowser not available");
};

export const openInBrowser = (url: string) => {
  if (!isMobileApp) {
    window.location.href = url;
    return;
  }

  const windowRef = openCordovaWindow(url);

  let completed = false;
  let closed = false;

  const closeBrowser = () => {
    closed = true;
    windowRef.close();
  };
  const { REACT_APP_FINSHARK_REDIRECT_URI: redirectUri } =
    process.env ?? "https://app.kvarnx.com";

  windowRef.addEventListener("loadstart", async (event: any) => {
    if (event.url.indexOf(redirectUri) === 0) {
      closeBrowser();
      completed = true;
    }
  });
  // https://localhost/?authorizationId=6efd0126-0b9c-4d73-88c5-6a55aa113e57&sessionId=6efd0126-0b9c-4d73-88c5-6a55aa113e57&result=success
  windowRef.addEventListener("loaderror", (event: any) => {
    if (completed) {
      return;
    }

    if (event.url.indexOf(redirectUri) === 0) {
      closeBrowser();
      completed = true;
    } else {
      console.error("Unknown loaderror", event);
    }
  });

  windowRef.addEventListener("exit", () => {
    if (!closed) {
      console.error("Browser closed unexpectedly");
    }
  });
};

// The FA API returns different files in different formats. We handle base64 encoded PDFs
// and blobs for this reason.
const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
};

export const mobileSaveFile = async (
  data: string | Blob,
  filename: string,
  successMessage: string,
  errorMessage: string
) => {
  if (typeof data !== "string") {
    data = await blobToBase64(data);
  }
  try {
    await Filesystem.writeFile({
      path: filename,
      data: data,
      directory: Directory.Documents,
    });
    toast.success(`${successMessage} ${Directory.Documents}/${filename}`);
  } catch (error) {
    toast.error(errorMessage);
  }
};

const addPushListeners = async () => {
  // This token is needed to add a device as a test device in the Firebase console
  if (isDevelopment) {
    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value);
    });
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
      }
    );
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );
  }

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
  });
};

export const LOGIN_COUNT_STORAGE_KEY = "loginCount";
const REQUIRED_LOGINS_FOR_NOTIF_PROMPT = 3;
export const NOTIF_PROMPTED_STORAGE_KEY = "notifPrompted";

export const shouldPromptForNotifications = () => {
  if (!isMobileApp) return false;

  const storedLoginCount = localStorage.getItem(LOGIN_COUNT_STORAGE_KEY) ?? "0";
  const loginCount = parseInt(storedLoginCount, 10);
  if (loginCount < REQUIRED_LOGINS_FOR_NOTIF_PROMPT) {
    return false;
  }
  const alreadyPrompted = localStorage.getItem(NOTIF_PROMPTED_STORAGE_KEY);

  return !alreadyPrompted;
};

export const bumpMobileAppLoginCount = () => {
  let prevLoginCount = localStorage.getItem(LOGIN_COUNT_STORAGE_KEY) ?? "0";
  const newLoginCount = parseInt(prevLoginCount, 10) + 1;
  localStorage.setItem(LOGIN_COUNT_STORAGE_KEY, newLoginCount.toString());
};

export const promptOSForNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    console.warn("User denied permissions.");
  }

  await PushNotifications.register();
};

export const usePushNotifications = () => {
  useEffect(() => {
    if (!isMobileApp) return;
    addPushListeners();
  }, []);
};
