import { ReactComponent as Spinner } from "assets/spinner.svg";
import { Center } from "../Center/Center";

interface LoadingIndicatorProps {
  center?: boolean;
  size?: "small" | "medium" | "large";
}

export const LoadingIndicator = ({
  center = false,
  size = "medium",
}: LoadingIndicatorProps) => {
  let spinnerSizeClass = "w-16 h-16"; // default to medium size
  if (size === "small") {
    spinnerSizeClass = "w-8 h-8";
  } else if (size === "large") {
    spinnerSizeClass = "w-32 h-32";
  }

  const SpinnerNode = (
    <Spinner
      className={`${spinnerSizeClass} text-gray-200 animate-spin fill-primary-600`}
    />
  );
  return center ? <Center>{SpinnerNode}</Center> : SpinnerNode;
};
